import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationState, projectAuthorizationScriptPath, projectGetScriptPath, redirectAddress } from './global-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'vfaSignalDB';
  token = '';
  loggedIn = false;
  IsAdmin = false;
  IsLoading = false;
  LoginRedirectAddress = redirectAddress;

  // Login form variables
  userMail = '';
  userPassword = '';
  showPassword = false;
  invalidLogIn = false;
  loginClicked = false;

  // Footer variables
  LastUpdatedDate = '';
  LastPracRecommendationDate = '';

  constructor(public router: Router, private http: HttpClient, private route: ActivatedRoute) {
    this.loggedIn = this.IsLoggedIn();
    console.log(this.loggedIn);
  }

  ngAfterViewInit(): void {
    this.loggedIn = this.IsLoggedIn();
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  checkLogInFormFilled(): void {
    this.loginClicked = true;
    if ((this.userPassword === '') || (this.userMail === '')) {
      this.invalidLogIn = true;
    } else {
      this.Validate().then();
      console.log(AuthorizationState.GetAuthorization());
    }
  }

  IsLoggedIn(): boolean {
    return AuthorizationState.GetAuthorization();
  }

  LogInWithRole(role): void {
    AuthorizationState.SetAuthorization(true);
    AuthorizationState.SetUserRole(role);
    this.loggedIn = true;
    this.IsAdmin = AuthorizationState.UserIsAdmin();
  }

  LogOut(): void {
    AuthorizationState.LogOut();
    this.loggedIn = false;
    location.reload();
  }

  async Validate(): Promise<any> {
    this.IsLoading = true;
    await this.route.queryParamMap.subscribe(async params => {
      if (AuthorizationState.GetAuthorization()) {
        return true;
      }

      // Prepare query
      const headers = new HttpHeaders(
        {
          'Content-Type': 'application/json; charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        }
      );
      let body;

      if (this.loginClicked) {
        // Login via login form
        body = {
          UserPW: this.userPassword,
          UserMail: this.userMail
        };
      } else {
        // Login via token
        this.token = params.get('token');
        body = {
          token: this.token
        };
      }

      const result = await this.http.post<any>(projectAuthorizationScriptPath, body, {headers}).toPromise();
      console.log(result);

      if (false) {
        this.LogInWithRole('ROLE_ADMIN');
      } else {
        if ('role' in result) {
          if (result.role === 'ROLE_USER' || result.role === 'ROLE_ADMIN') {
            this.LogInWithRole(result.role);
          } else {
            AuthorizationState.SetAuthorization(false);
            // window.location.href = redirectAddress;
          }
        } else if (AuthorizationState.AuthorizationRequestPosted()) {
          AuthorizationState.SetAuthorization(false);
          // window.location.href = redirectAddress;
        }
        AuthorizationState.SetAuthorizationRequestPostStatus(true);
      }
    });
    this.loginClicked = false;
    this.IsLoading = false;
    return AuthorizationState.GetAuthorization();
  }

  ngOnInit(): void {
    this.Validate().then();

    const body = {
      GetDatabaseSettings: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.LastUpdatedDate = response.FLastUpdated;
      this.LastPracRecommendationDate = response.FLatestRecommendationDate;
    });
  }
}
