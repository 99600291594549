import { Component, OnInit } from '@angular/core';
import { projectGetScriptPath, projectScriptPath } from '../../global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExportSubstancesNetworksAsExcel } from '../../shared/services/excel-export';

@Component({
  selector: 'app-pascal-main',
  templateUrl: './pascal-main.component.html',
  styleUrls: ['./pascal-main.component.css']
})
export class PascalMainComponent implements OnInit {
  pascalScriptsPath = projectScriptPath + '/pascal_main.php';
  network;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  Test(): void {
    const body = {
      GenerateActiveSubstanceNetworkEdges: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(this.pascalScriptsPath, body, {headers}).subscribe((response => {
      this.network = response;
      console.log(this.network[10]);
      ExportSubstancesNetworksAsExcel(response, 'Signal-DB_Substances_network', this.http);
    }));
  }

}
