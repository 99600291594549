// ------------------------ User data ------------------------
export class AuthorizationState {

  constructor() {
  }
  public static userAuthorized = false;
  public static userRole = '';
  public static AuthorizationRequestPostStatus = false;
  public static token = '';

  static GetAuthorization(): boolean {
    return ((this.userAuthorized && this.AuthorizationRequestPosted()) || (sessionStorage.getItem('authorized') === 'true'));
  }

  static SetAuthorization(authorized: boolean): void {
    if (this.userAuthorized) {
      return;
    }

    this.userAuthorized = authorized;

    if (!authorized) {
      this.userRole = '';
      sessionStorage.clear();
    } else {
      // sessionStorage.setItem('authorized', 'true');
    }
  }

  static GetUserRole(): string {
    if (sessionStorage.getItem('role') !== '') {
      return sessionStorage.getItem('role');
    } else {
      return this.userRole;
    }
  }

  static UserIsAdmin(): boolean {
    return this.GetUserRole() === 'ROLE_ADMIN';
  }

  static UserIsNormalUser(): boolean {
    return this.userRole === 'ROLE_USER';
  }

  static SetUserRole(userRole: string): void {
    this.userRole = userRole;
    sessionStorage.setItem('role', userRole);
  }

  static AuthorizationRequestPosted(): boolean {
    return this.AuthorizationRequestPostStatus;
  }

  static SetAuthorizationRequestPostStatus(requestSent: boolean): void {
    this.AuthorizationRequestPostStatus = requestSent;
  }

  static SetToken(sessionToken: string): void {
    if (sessionToken !== null) {
      this.token = sessionToken;
    }
  }

  static GetToken(): string {
    return this.token;
  }

  static LogOut(): void {
    sessionStorage.clear();
    this.userAuthorized = false;
    this.token = '';
    this.AuthorizationRequestPostStatus = false;
    this.userRole = '';
  }
}

// ------------------------ Paths ------------------------
export const projectWebAddress = 'https://vfa-signals.de';
export const redirectAddress = 'https://www.vfa.de/signaldatenbank-weiterleitung';

// Scripts
export const projectScriptPath = projectWebAddress + '/scripts';
export const projectGetScriptPath = projectScriptPath + '/molar_get.php';
// export const projectGetScriptPath = 'https://vfa-signals.de/scripts/molar_get.php';
export const projectSetScriptPath = projectScriptPath + '/molar_add.php';
export const projectAuthorizationScriptPath = projectScriptPath + '/validation.php'; // validation.php for deploy; access.php for testing
// Media
export const projectMediaPath = projectWebAddress + '/media';
