import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LogService } from '../../shared/services/login.service';
import { projectGetScriptPath, AuthorizationState, projectSetScriptPath} from '../../global-constants';

@Component({
  selector: 'app-substance-wiki',
  templateUrl: './substance-wiki.component.html',
  styleUrls: ['./substance-wiki.component.css']
})
export class SubstanceWikiComponent implements OnInit {
  SubstanceID: string;
  SubstanceINN: string;
  Signals = [];
  RelatedSignals = [];
  DrugProducts = [];
  TherapeuticClasses = [];
  RelatedSubstances = [];
  RenameINN: string;
  siteURL: Location;
  DeleteSubstanceModalRef: BsModalRef;
  RenameSubstanceModalRef: BsModalRef;

  constructor(private http: HttpClient, private router: Router, private location: Location,
              private LogInService: LogService, private modalService: BsModalService) {
    this.siteURL = location;
  }

  getSubstance(): string {
    return this.router.url.split('/')[3];
  }

  loadSubstanceByID(sid): void {
    const body = {
      GetSubstanceByID: '',
      SubstanceID: sid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.SubstanceINN = response.INN_EN;
      this.SubstanceID = response.AI_ID;
      this.loadRelatedActiveSubstances(response.INN_EN);
      this.loadRelatedSignals(response.INN_EN);
    });
  }

  loadSignalsBySubstanceID(sid): void {
    const body = {
      GetSignalsBySubstanceID: '',
      SubstanceID: sid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.Signals = response;

      for (const item of this.Signals) {
        let isFinal = false;
        for (const se of item.SignalEvents) {
          if (se.IsFinal === '1') {
            isFinal = true;
          }
        }
        item.IsFinal = isFinal;
      }
    });
  }

  loadDrugProductsBySubstanceID(sid): void {
    const body = {
      GetDrugProductsBySubstanceID: '',
      SubstanceID: sid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.DrugProducts = response;
    });
  }

  loadRelatedTherapeuticClasses(sid): void {
    const body = {
      GetTherapeuticClassesBySubstanceID: '',
      SubstanceID: sid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.TherapeuticClasses = response;
    });
  }

  loadRelatedActiveSubstances(INN): void {
    const body = {
      GetRelatedSubstancesBySubstanceName: '',
      SubstanceName: INN
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.RelatedSubstances = response;
    });
  }

  loadRelatedSignals(INN): void {
    const body = {
      GetRelatedSignalsByTerm: '',
      Term: INN
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.RelatedSignals = response;
      for (const item of this.RelatedSignals) {
        let isFinal = false;
        for (const se of item.SignalEvents) {
          if (se.IsFinal === '1') {
            isFinal = true;
          }
        }
        item.IsFinal = isFinal;
      }

      this.eliminateDuplicatesFromRelatedSignals();
    });
  }

  eliminateDuplicatesFromRelatedSignals(): void {
    const epitts = this.Signals.map(EPITT => {
      EPITT = EPITT.Signal.EPITT;
      return EPITT;
    });

    let duplicates = [];

    for (let i = 0; i < this.RelatedSignals.length; i++) {
      for (const item of epitts) {
        if (this.RelatedSignals[i].Signal.EPITT === item) {
          duplicates = duplicates.concat(i);
        }
      }
    }

    for (let i = 0; i < duplicates.length; i++) {
      this.RelatedSignals.splice(duplicates[i] - i, 1);
    }
  }

  openDeleteCurrentSubstanceModal(template: TemplateRef<any>): void {
    this.DeleteSubstanceModalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  deleteCurrentSubstance(): void {
    this.deleteSubstanceByID(this.SubstanceID);
    this.DeleteSubstanceModalRef.hide();
    this.loadSubstanceByID(this.SubstanceID);
  }

  deleteSubstanceByID(deleteSubstanceID): void {
    const body = {
      DeleteSubstanceByID: '',
      AI_ID: deleteSubstanceID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body, {headers}).subscribe();
  }

  openRenameSubstanceModal(template: TemplateRef<any>): void {
    this.RenameINN = this.SubstanceINN;
    this.RenameSubstanceModalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  renameCurrentSubstance(): void {
    this.renameSubstanceByID(this.SubstanceID, this.RenameINN);
    this.RenameSubstanceModalRef.hide();
    this.loadSubstanceByID(this.SubstanceID);
  }

  renameSubstanceByID(substanceID, newINN): void {
    const body = {
      RenameSubstanceByID: '',
      AI_ID: substanceID,
      NewINN: newINN
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body, {headers}).subscribe();
  }

  setURLtoSubstanceID(oldID, newID): void {
    this.siteURL.replaceState('substance/' + oldID, 'substance/' + newID);
  }

  ngOnInit(): void {
    this.loadSubstanceByID(this.getSubstance());
    this.loadSignalsBySubstanceID(this.getSubstance());
    this.loadDrugProductsBySubstanceID(this.getSubstance());
    this.loadRelatedTherapeuticClasses(this.getSubstance());
  }

  setSubstanceByID(id): void {
    this.setURLtoSubstanceID(this.getSubstance(), id);
    this.loadSubstanceByID(id);
    this.loadSignalsBySubstanceID(id);
    this.loadDrugProductsBySubstanceID(id);
    this.loadRelatedTherapeuticClasses(id);
  }

  IsLoggedIn(): boolean {
    return AuthorizationState.UserIsAdmin();
  }
}
