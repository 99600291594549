export class SignalClass {
  epitt: number;
  term: string;
  source: number;
  sourceAsString: string;
  rapporteur: string;
  rapporteurID: number;
  authorizationType: number;
  committee: number;
  isCAP: boolean;
  isNAP: boolean;
  comment: string;
  opinion: string;
  substances: any;
  therapeuticClasses: any;
  signalEvents: any;
  signalRecommendations: any;
  additionalRapporteurs: string;

  constructor(epitt: number, term: string, source: number, rapporteur: string, authorizationType: number, committee: number,
              comment: string, isCAP: boolean, isNAP: boolean, opinion: string, substances, therapeuticClasses, signalRecommendations) {
    this.epitt = epitt;
    this.term = term;
    this.source = source;
    this.rapporteur = rapporteur;
    this.authorizationType = authorizationType;
    this.committee = committee;
    this.isCAP = isCAP;
    this.isNAP = isNAP;
    this.comment = comment;
    this.opinion = opinion;
    this.substances = substances;
    this.therapeuticClasses = therapeuticClasses;
    this.signalRecommendations = signalRecommendations;
    if (this.source === 0) {
      this.sourceAsString = 'EU-SRS';
    } else {
      this.sourceAsString = 'Others';
    }
  }

  public GetSource(): string {
    if (this.source === 0) {
      return 'EU&#8209;SRS';
    } else {
      return 'Others';
    }
  }

  public GetAuthorizationType(): string {
    if (this.isNAP && this.isCAP) {
      return 'CAP & NAP';
    } else if (this.isCAP) {
      return 'CAP';
    } else {
      return 'NAP';
    }
  }
}

export function AuthorizationTypeToString(signal): string {
  if ((signal.isNAP === '1') && (signal.isCAP === '1')) {
    return 'CAP & NAP';
  } else if (signal.isCAP === '1') {
    return 'CAP';
  } else {
    return 'NAP';
  }
}

export function RecommendationsToString(recommendations): string {
  let result = '';

  for (let i = 0; i < recommendations.length; i++) {
    if (i === 0) {
      result = result + recommendations[i].PRAC_Signal_Recommendation;
    } else {
      result = result + '\n' + recommendations[i].PRAC_Signal_Recommendation;
    }
  }

  return result;
}

export function SubstancesAndClassesToString(activeSubstances, therapeuticClasses): string {
  let result = '';

  for (let i = 0; i < activeSubstances.length; i++) {
    if (i === 0) {
      result = result + activeSubstances[i].INN_EN;
    } else {
      result = result + '\n' + activeSubstances[i].INN_EN;
    }
  }

  for (const therapeuticClass of therapeuticClasses) {
    if (activeSubstances.length === 0) {
      result = result + therapeuticClass.Description_EN;
    } else {
      result = result + '\n' + therapeuticClass.Description_EN;
    }
  }

  return result;
}

export function GetFinalOutcomeAsString(signal): string {
  let result = '';

  for (const signalEvent of signal.signalEvents) {
    if (signalEvent.IsFinal === '1') {
      for (let j = 0; j < signalEvent.Recommendations.length; j++) {
        if (j === 0) {
          result = result + signalEvent.Recommendations[j].Final_outcome;
        } else {
          result = result + '\n' + signalEvent.Recommendations[j].Final_outcome;
        }
      }
    }
  }

  return result;
}

export function GetRapporteursAsString(signal): string {
  let result = signal.rapporteur;

  for (const rapporteur of signal.additionalRapporteurs) {
    result = result + ', ' + rapporteur.RapporteurCode;
  }

  return result;
}
