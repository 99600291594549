<div style="margin-left: 10px" class="mainContent">
  <h2>{{ClassName}}</h2>
  <div style="color: #4e555b; font-size: large; margin-top: -4pt">
    Class Name
  </div>
  <hr>

  <table class="statisticsTable">
    <tr class="value">
      <td>
        {{ClassID}}
      </td>
      <td class="red">
        {{Signals.length}}
      </td>
      <td class="blue">{{Substances.length}}</td>
    </tr>
    <tr class="label">
      <td>Class ID</td>
      <td>RELATED SIGNALS</td>
      <td>Active Substances</td>
    </tr>
  </table>
  <hr>

  <h4>Signals</h4>
  <table class="table table-hover" style="width: 70%">
    <thead>
    <tr>
      <th scope="col">EPITT</th>
      <th scope="col">Substances</th>
      <th scope="col">Term</th>
      <th scope="col">Final Outcome</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let i of Signals">
      <td><a [routerLink]="['../../signal/', i['Signal']['EPITT']]">{{i['Signal']['EPITT']}}</a></td>
      <td>
        <span *ngFor="let substances of i['Substances']">
           <a [routerLink]="['../../substance/', substances['AI_ID']]">{{substances['INN_EN']}}</a>
           <br>
        </span>
        <span *ngFor="let classes of i['TherapeuticClasses']">
          <a (click)="setClassByID(classes['TC_ID'])" [routerLink]="['../../therapeutic-class/', classes['TC_ID']]">{{classes['Description_EN']}}</a>
          <br>
        </span>
      </td>
      <td>{{i['Signal']['Term']}}</td>
      <td *ngIf="i['IsFinal'] else ongoing" style="font-weight: bold">
        <div class="ui list">
          <div *ngFor="let se of i['SignalEvents']">
            <div *ngIf="se['IsFinal'] === '1'">
              <div *ngFor="let rec of se['Recommendations']">
                {{rec['Final_outcome']}}
              </div>
              <div *ngIf="se['OtherRecommendation'] !== ''">
                {{se['OtherRecommendation']}}
              </div>
            </div>
          </div>
          <div class="item"></div>
        </div>
      </td>
      <ng-template #ongoing>
        <td style="font-weight: bold; color: #660000">
          Ongoing
        </td>
      </ng-template>
    </tr>
    </tbody>
  </table>
  <hr>


  <h4>Active Substances</h4>
  <div class="row">
    <div *ngIf="Substances.length === 0" class="col">
      <i>No substances have been assigned to this class.</i>
    </div>
    <div class="col">
      <div class="list-group">
        <a *ngFor="let substance of Substances.slice(0, (Substances.length/2)+1)" class="list-group-item list-group-item-action"
           [routerLink]="['../../substance/', substance['AI_ID']]">
          {{substance['INN_EN']}}
        </a>
      </div>
    </div>
    <div class="col">
      <div class="list-group">
        <a *ngFor="let substance of Substances.slice((Substances.length/2)+1, Substances.length)" class="list-group-item list-group-item-action"
           [routerLink]="['../../substance/', substance['AI_ID']]">
          {{substance['INN_EN']}}
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="IsLoggedInAsAdmin()" style="margin-top: 2%">
    <h5>Add Substances to Therapeutic Class</h5>
    <div class="row">
      <div class="col-xs-6 col-6 col-md-5 col-lg-3">
        <input type="text" class="form-control" placeholder="Search for INN" [(ngModel)]="ActiveSubstancesFilterTerm"
               (ngModelChange)="filterAllActiveSubstances()">
      </div>
      <div class="col-xs-6 col-6 col-md-5 col-lg-3">
        Drag Substances here
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-6 col-md-5 col-lg-3" style="border-right: solid 1px black; overflow: scroll; height: 300px">
        <bs-sortable
          [(ngModel)]="displayAllActiveSubstances"

          itemClass="sortable-items"
          itemActiveClass="sortable-item-active"
          placeholderItem="Drag here"
          placeholderClass="placeholderStyle"
          wrapperClass="sortable-wrapper"
        ></bs-sortable>
      </div>
      <div class="col-xs-6 col-6 col-md-5 col-lg-3">
        <bs-sortable
          [(ngModel)]="selectedActiveSubstances"

          itemClass="sortable-items"
          itemActiveClass="sortable-item-active"
          placeholderItem="Drag here"
          placeholderClass="placeholderStyle"
          wrapperClass="sortable-wrapper"
        ></bs-sortable>
      </div>
    </div>
  </div>

  <div *ngIf="IsLoggedInAsAdmin()" style="margin-top: 2%">
    <h4>Actions</h4>
    <div class="btn-group" role="group" aria-label="Button Group" style="color: white">
      <button type="button" class="btn btn-primary" (click)="addSubstancesToClass()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-square" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Update Active Substances
      </button>
      <ng-template #addAndAssignSubstanceModal>
        <div class="modal-body text-center">
          <p>Add new active substance to class "{{ClassName}}"</p>
          <input type="text" [(ngModel)]="NewSubstanceINN">
          <br><br>
          <button type="button" class="btn btn-default btn-primary" (click)="this.AddNewSubstanceAndAssignToClass()">Add New Substance</button>
          <button type="button" class="btn btn-default" (click)="this.AddNewSubstanceAndAssignToClassModalRef.hide()">Cancel</button>
        </div>
      </ng-template>
      <button type="button" class="btn btn-primary" (click)="OpenAddAndAssignNewSubstanceModal(addAndAssignSubstanceModal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="white" class="bi bi-bag-plus-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zM8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5V8z"/>
        </svg>
        Create Substance and add to Class
      </button>
      <!--<button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
        </svg>
        Delete Therapeutic Class
      </button>-->
    </div>
  </div>


<!--
  <br>
  <div *ngIf="">
    <h5>Add Active Substance(s)</h5>
    <form>

      <! Buttons --
      <div>
      </div>>
    </form>
  </div>


  <form class="ui form" method="post" (ngSubmit)="addSubstancesToClass()">
    <sui-multi-select [options]="selectActiveSubstances" icon="list" #select [(ngModel)]="activeSubstances"
                      name="[activeSubstances]">
      <div class="ui icon search input">
        <i class="search icon"></i>
        <input suiSelectSearch type="text" placeholder="Select Active Substance">
      </div>
      <div class="divider"></div>
      <div class="header">
        <i class="list icon"></i>
        Options
      </div>
      <div class="scrolling menu">
        <sui-select-option *ngFor="let o of select.filteredOptions" [value]="o"></sui-select-option>
      </div>
    </sui-multi-select>
    <br>
    <button type="submit" class="ui primary button">Add Substance(s) to Class</button>
  </form>-->
</div>
