import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { projectSetScriptPath, projectGetScriptPath } from '../../global-constants';

@Component({
  selector: 'app-edit-drug-product',
  templateUrl: './edit-drug-product.component.html',
  styleUrls: ['./edit-drug-product.component.css']
})
export class EditDrugProductComponent implements OnInit, AfterViewInit {
  DrugProductName = '';
  DrugProductMAH = '';
  DrugProductButtonCaption = 'Add Drug Product';
  DrugProductID = 0;
  DrugProductActiveSubstances: string;
  DrugProductActiveSubstancesList: string[];
  AllActiveSubstances: string[];
  ModifyMode = false; // 0: Add; 1: Modify

  validDrugProductName = 'collapse';
  validMAH = 'collapse';
  validActiveSubstance = 'collapse';

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.GetAllActiveSubstances();
  }

  getMode(): boolean {
    if (this.getDrugProductID() !== null) {
      this.DrugProductButtonCaption = 'Modify Drug Product';
      this.ModifyMode = true;
    } else {
      this.DrugProductButtonCaption = 'Add Drug Product';
      this.ModifyMode = false;
    }

    return this.ModifyMode;
  }

  getDrugProductID(): string {
    return this.route.snapshot.paramMap.get('id');
  }

  LoadDrugProduct(): void {
    const body = {
      GetDrugProductByID: '',
      DrugProductID: this.DrugProductID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        console.log(response);
        this.DrugProductMAH = response.Drug_Product.MAH;
        this.DrugProductName = response.Drug_Product.EU_Name;

        this.DrugProductActiveSubstances = '';

        for (let i = 0; i < response.Substances.length; i++) {
          this.DrugProductActiveSubstances += response.Substances[i].INN_EN;
          if (i < response.Substances.length - 1) {
            this.DrugProductActiveSubstances += ', ';
          }
        }
      }
    );
  }

  GetAllActiveSubstances(): void {
    const body = {
      GetAllActiveSubstances: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.AllActiveSubstances = response;
      }
    );
  }

  AddDrugProduct(): void {
    const body = {
      AddDrugProduct: '',
      DrugProductName: this.DrugProductName,
      DrugProductMAH: this.DrugProductMAH,
      DrugProductSubstances: this.DrugProductActiveSubstancesList
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
        if (response === null) {
          this.ClearDrugProductForm();
        }
      }
    );
  }

  ModifyDrugProduct(): void {
    const body = {
      ModifyDrugProduct: '',
      DrugProductID: this.DrugProductID,
      DrugProductName: this.DrugProductName,
      DrugProductMAH: this.DrugProductMAH,
      DrugProductSubstances: this.DrugProductActiveSubstancesList
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
        if (response === null) {
          this.ClearDrugProductForm();
        }
      }
    );
  }

  DeleteDrugProduct(): void {
    const body = {
      DeleteDrugProductByID: '',
      DrugProductID: this.DrugProductID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
        console.log(response);
      }
    );
    this.ClearDrugProductForm();
  }

  ClearDrugProductForm(): void {
    this.DrugProductName = '';
    this.DrugProductMAH = '';
    this.DrugProductActiveSubstances = '';
    this.DrugProductActiveSubstancesList = [];
    this.DrugProductButtonCaption = 'Add Drug Product';
    this.ModifyMode = false;
    this.DrugProductID = null;
  }

  ngAfterViewInit(): void {
    if (this.getMode()) {
      this.DrugProductID = +this.getDrugProductID().split(':')[1];
      this.LoadDrugProduct();
    }
  }

  ValidateForm(): void {
    if (this.DrugProductName === '') {
      this.validDrugProductName = '';
    } else if (this.DrugProductMAH === '') {
      this.validMAH = '';
    } else if (this.DrugProductActiveSubstances === '') {
      this.validActiveSubstance = '';
    } else {
      let substances = this.DrugProductActiveSubstances;
      substances = substances.replace(';', ',');
      substances = substances.replace('|', ',');

      this.DrugProductActiveSubstancesList = [];

      for (const i of substances.split(',')) {
        this.DrugProductActiveSubstancesList.push(i.trim());
      }

      if (!this.ModifyMode) {
        this.AddDrugProduct();
      } else {
        this.ModifyDrugProduct();
      }
    }
  }

}
