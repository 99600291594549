import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { StartPageComponent } from './start-page/start-page.component';
import { StatisticsPageComponent } from './statistics/statistics-page/statistics-page.component';
import { SignalsOverviewComponent } from './signals-overview/signals-overview.component';
import { EditDbMainComponent } from './edit-db/edit-db-main/edit-db-main.component';
import { SignalWikiComponent } from './wikis/signal-wiki/signal-wiki.component';
import { SubstanceWikiComponent } from './wikis/substance-wiki/substance-wiki.component';
import { TherapeuticclassWikiComponent } from './wikis/therapeuticclass-wiki/therapeuticclass-wiki.component';
import { OverviewOnSignalsComponent } from './statistics/charts/overview-on-signals/overview-on-signals.component';
import { FinalOutcomesComponent } from './statistics/charts/final-outcomes/final-outcomes.component';
import { RapporteursByYearComponent } from './statistics/charts/rapporteurs-by-year/rapporteurs-by-year.component';
import { SearchDbComponent } from './search/search-db/search-db.component';
import { LoginStatusComponent } from './login-status/login-status.component';
import { AboutComponent } from './about/about.component';
import { EditDrugProductComponent } from './edit-db/edit-drug-product/edit-drug-product.component';
import { SingleSignOnService } from './shared/services/single-sign-on.service';
import { SignalsByMeetingsComponent } from './signals/signals-by-meetings/signals-by-meetings.component';
import { SignalTableComponent } from './shared/components/signal-table/signal-table.component';
import {SortableComponent, SortableModule} from 'ngx-bootstrap/sortable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PascalMainComponent } from './pascal/pascal-main/pascal-main.component';
import { RapporteurWikiComponent } from './wikis/rapporteur-wiki/rapporteur-wiki.component';
import { SmallSignalTableComponent } from './shared/components/small-signal-table/small-signal-table.component';
import { UsersChartComponent } from './statistics/charts/users-chart/users-chart.component';
import { LoginFormComponent } from './shared/components/login-form/login-form.component';
import { DrugProductWikiComponent } from './wikis/drug-product-wiki/drug-product-wiki.component';

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    StatisticsPageComponent,
    SignalsOverviewComponent,
    EditDbMainComponent,
    SignalWikiComponent,
    SubstanceWikiComponent,
    TherapeuticclassWikiComponent,
    OverviewOnSignalsComponent,
    FinalOutcomesComponent,
    RapporteursByYearComponent,
    SearchDbComponent,
    LoginStatusComponent,
    AboutComponent,
    EditDrugProductComponent,
    SignalsByMeetingsComponent,
    SignalTableComponent,
    PascalMainComponent,
    RapporteurWikiComponent,
    SmallSignalTableComponent,
    UsersChartComponent,
    LoginFormComponent,
    DrugProductWikiComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    AccordionModule.forRoot(),
    // RouterModule.forRoot(routes, { enableTracing: true }),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    SortableModule.forRoot()
  ],
  providers: [SingleSignOnService],
  bootstrap: [AppComponent]
})

export class AppModule {}
