<img src="https://vfa-signals.de/media/logo-vfa.jpg" height="50px" style="position: absolute;top: 20px; left: 20px" alt="vfa-Logo">
<div class="login-form">
  <h1>Login</h1>
  <div *ngIf="invalidLogIn" id="invalid_login_hint">
    <alert type="danger">
      Incorrect username or password. Please try again.
    </alert>
  </div>
  <form (ngSubmit)="checkLogIn()">
    <input type="text" placeholder="Company e-mail address" [required]="true" [(ngModel)]="userMail" id="loginMail"
           name="user_mail">
    <!--<input type="password" placeholder="Password" required="required" [(ngModel)]="userPassword">-->
    <div class="password-container">
      <input type="password" placeholder="Password" [required]="true" [type]="showPassword ? 'text' : 'password'"
             [(ngModel)]="userPassword" id="loginPW" name="user_password">
      <button (click)="togglePassword()" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
        </svg>
      </button>
    </div>
    <input type="submit" value="Log in">
    <a href="/register">No account yet? Register here</a>
  </form>
</div>
