import { Component, OnInit } from '@angular/core';
import { AuthorizationState, projectGetScriptPath } from '../../global-constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rapporteur-wiki',
  templateUrl: './rapporteur-wiki.component.html',
  styleUrls: ['./rapporteur-wiki.component.css']
})
export class RapporteurWikiComponent implements OnInit {
  RapporteurID = 0;
  RapporteurFlag = '';
  RapporteurCountry = '';
  RapporteurCode = '';
  RapporteurSignals;

  constructor(private http: HttpClient, private router: Router) { }

  GetRapporteurID(): number {
    return +this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    this.RapporteurID = this.GetRapporteurID();
    this.GetRapporteurInfo(this.RapporteurID);
  }

  GetRapporteurInfo(id): void {
    const body = {
      GetRapporteurInfoByID: '',
      RapporteurID: id
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.RapporteurFlag = response.RapporteurFlag;
        this.RapporteurCountry = response.RapporteurFull;
        this.RapporteurCode = response.RapporteurCode;
        this.RapporteurSignals = response.Signals;

        for (const item of this.RapporteurSignals) {
          let isFinal = false;
          for (const se of item.SignalEvents) {
            if (se.IsFinal === '1') {
              isFinal = true;
            }
          }
          item.IsFinal = isFinal;
        }
        console.log(response);
      }
    );
  }

  IsAdmin(): boolean {
    return AuthorizationState.UserIsAdmin();
  }

}
