import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { projectGetScriptPath } from '../../../global-constants';

@Component({
  selector: 'app-final-outcomes',
  templateUrl: './final-outcomes.component.html',
  styleUrls: ['./final-outcomes.component.css']
})
export class FinalOutcomesComponent implements OnInit {

  public selectedYear = 'Accumulated';
  public possibleYears = [];
  public togglePercentages = false;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {position: 'right'}
  };
  public pieChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData: SingleDataSet = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Array<any> = [
    {
      backgroundColor: [
        '#172e4c',
        '#024161',
        '#036292',
        '#0482c3',
        '#48befb',
        '#85d4fd',
        '#c2e9fe',
        '#b0c8e7',
        '#b6cae2',
        '#dfe9f5'
      ]
    }
  ];

  constructor(private http: HttpClient) { }

  getData(year): void {
    let body;
    body = {
      GetFinalOutcomes: ''
    };
    if (year !== 'Accumulated') {
      body = {
        GetFinalOutcomesByYear: '',
        Year: year
      };
    }
    let data: number[];
    data = [];

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.pieChartData = [];
        this.pieChartLabels = [];
        for (const item of response) {
          data.push(+item[0]);
          this.pieChartLabels.push(item[1]);
        }

        if (this.togglePercentages) {
          let sum: number;
          sum = 0;

          data.forEach(item => {
            sum = sum + item;
          });

          for (let i = 0; i < response.length; i++) {
            data[i] = +(100 * data[i] / sum).toFixed(2);
          }
        }

        this.pieChartData = data;
      }
    );
  }

  getYears(): void {
    const body = {
      GetMinMaxSignalDates: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.possibleYears = ['Accumulated'];
      const minYear = new Date(response['MIN(SignalEvent.Date)']);
      const maxYear = new Date(response['MAX(SignalEvent.Date)']);
      for (let i = minYear.getFullYear(); i <= maxYear.getFullYear(); i++) {
        this.possibleYears.push(i);
      }
    });
  }

  changeYear(): void {
    this.getData(this.selectedYear);
  }

  ngOnInit(): void {
    this.getData(this.selectedYear);
    this.getYears();
  }

}
