import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AuthorizationState, projectAuthorizationScriptPath, redirectAddress } from '../../../global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoginFormComponent),
      multi: true
    }
  ]
})

export class LoginFormComponent implements OnInit, ControlValueAccessor {
  @Input() private _LoggedIn: any;

  invalidLogIn = false;
  public userMail = '';
  public userPassword = '';

  public showPassword = false;

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.propagateChange(this._LoggedIn);
  }

  checkLogIn(): void {
    if ((this.userPassword === '') || (this.userMail === '')) {
      this.invalidLogIn = true;
    } else {
      this.VerifyLogin().then();
    }
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  async VerifyLogin(): Promise<any> {
    if (AuthorizationState.GetAuthorization()) {
      return true;
    }
    const path = projectAuthorizationScriptPath;
    const body = {
      UserPW: this.userPassword,
      UserMail: this.userMail
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
      }
    );

    const result = await this.http.post<any>(path, body, {headers}).toPromise();
    console.log(result);

    if (false) {
      AuthorizationState.SetAuthorization(true);
      AuthorizationState.SetUserRole('ROLE_ADMIN');
    } else {
      if ('role' in result) {
        console.log(result);
        if (result.role === 'ROLE_USER' || result.role === 'ROLE_ADMIN') {
          AuthorizationState.SetAuthorization(true);
          AuthorizationState.SetUserRole(result.role);
          // location.reload();
          console.log(AuthorizationState.GetAuthorization());
          this.LoggedIn = true;
          await this.router.navigate(['./']);
        } else {
          this.LoggedIn = false;
          AuthorizationState.SetAuthorization(false);
        }
      } else if (AuthorizationState.AuthorizationRequestPosted()) {
        this.LoggedIn = false;
        AuthorizationState.SetAuthorization(false);
      }
      // AuthorizationState.SetAuthorizationRequestPostStatus(true);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange(fn);
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this._LoggedIn = obj;
    }
  }

  get LoggedIn(): any {
    return this._LoggedIn;
  }

  set LoggedIn(value: any) {
    this._LoggedIn = value;
    this.propagateChange(this._LoggedIn);
  }

}
