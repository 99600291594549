<div *ngIf="loggedIn; else notLoggedIn" class="mainContent">
  <h2>Add Data</h2>
  <tabset #staticTabs>
    <tab heading="Signal">
      <div style="width: 30%" class="contentTabBorder">
        <form (submit)="ValidateSignalForm()">
          <div class="form-group">
            <label for="SignalEPITT">EPITT:</label>
            <input type="text" class="form-control" name="signal-epitt" id="SignalEPITT" [maxLength]="5" [(ngModel)]="SignalEPITT"
                   required="required" placeholder="EPITT" autocomplete="off">
          </div>
          <alert type="danger" class="{{validEPITT}}">
            <strong>Required!</strong> You must provide a valid EPITT number.
          </alert>
          <alert type="danger" class="{{validRSignal}}">
            <strong>Duplicate found!</strong> A signal with this EPITT already exists.
          </alert>
          <alert type="danger" class="{{validRDelete}}">
            <strong>Signal not found!</strong> A signal with this EPITT does not exist.
          </alert>

          <div class="form-group">
            <label for="SignalTerm">Term:</label>
            <textarea class="form-control" rows="3" id="SignalTerm" [(ngModel)]="SignalTerm"
                      required="required" name="signal-term" placeholder="Signal Description"></textarea>
          </div>
          <alert type="danger" class="{{validTerm}}">
            <strong>Required!</strong> You must provide a term/description for this signal.
          </alert>

          <div class="form-group">
            <label for="SignalActiveSubstances">Active Substance(s):</label>
            <textarea class="form-control" rows="3" id="SignalActiveSubstances" [(ngModel)]="SignalSubstances"
                      placeholder="Active Substance(s)" name="signal-activesubstance" required="required"></textarea>
          </div>

          <div class="form-group">
            <label for="SignalTherapeuticClass">Therapeutic Class(es):</label>
            <textarea class="form-control" rows="3" id="SignalTherapeuticClass" [(ngModel)]="SignalTherapeuticClasses"
                      placeholder="Therapeutic Class(es)" name="signal-therapeuticClasses" required="required">
            </textarea>
          </div>
          <alert type="danger" class="{{validSubstanceClass}}">
            <strong>Required!</strong> You must provide either at least one active substance or one therapeutic class connected to this signal.
          </alert>

          <fieldset class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-2 pt-0">Source:</legend>
              <div class="col-sm-10">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="signal-source" id="SignalSourceEUSRS"
                         [value]="0" [(ngModel)]="SignalSource">
                  <label class="custom-control-label" for="SignalSourceEUSRS">EU Spontaneous Reporting System</label>
                </div>
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="signal-source" id="SignalSourceOthers"
                         [value]="1" [(ngModel)]="SignalSource">
                  <label class="custom-control-label" for="SignalSourceOthers">Others</label>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="form-group">
            <label for="SignalRapporteur">Rapporteur:</label>
            <input type="text" class="form-control" name="signal-rapporteur" id="SignalRapporteur"
                   [(ngModel)]="SignalRapporteur" required="required" placeholder="Rapporteur Country">
          </div>
          <alert type="danger" class="{{validRapporteur}}">
            <strong>Required!</strong> You must provide the rapporteur country / countries.
            If there hasn't been any nomination please type "tba"
          </alert>
          <alert type="danger" class="{{validRapporteurExisting}}">
            <strong>Invalid input!</strong> The provided rapporteur country does not exist.
            <table  class="ui celled compact table" style=" width: 20%">
              <thead>
              <tr>
                <td>Country</td>
                <td>Rapporteur Code</td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let r of rapporteurs">
                <td>{{r['RapporteurFull']}}</td>
                <td>{{r['RapporteurCode']}}</td>
              </tr>
              </tbody>
            </table>
          </alert>

          <fieldset class="form-group">
            <div class="row">
              <div class="col-form-label col-6 pt-0">Authorization Type:</div>
              <div class="col">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" name="signal-authorization-type-cap" id="SignalAuthorizationTypeCAP"
                         [(ngModel)]="SignalIsCAP">
                  <label class="custom-control-label" for="SignalAuthorizationTypeCAP">CAP</label>
                </div>
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" name="signal-authorization-type-nap" id="SignalAuthorizationTypeNAP"
                         [(ngModel)]="SignalIsNAP">
                  <label class="custom-control-label" for="SignalAuthorizationTypeNAP">NAP</label>
                </div>
              </div>
            </div>
          </fieldset>
          <alert type="danger" class="{{validAuthorizationtype}}">
            <strong>Required!</strong> You must provide either at least one authorization type for the affected products.
          </alert>

          <div class="form-group">
            <label for="SignalComment">Comment:</label>
            <textarea class="form-control" rows="3" id="SignalComment" name="signal-comment" [(ngModel)]="SignalComment"
                      placeholder="Comment"></textarea>
          </div>

          <div class="form-group">
            <label for="SignalOpinion">Opinion:</label>
            <textarea class="form-control" rows="3" id="SignalOpinion" name="signal-opinion" [(ngModel)]="SignalOpinion"
                      placeholder="PRAC Opinion"></textarea>
          </div>

          <div>
            <button type="button" class="btn btn-outline-secondary form-button" (click)="ClearSignalForm()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary form-button">
              {{buttonCaption}}
            </button>
            <button type="button" class="btn btn-outline-danger form-button" (click)="openModal(confirmDeleteSignal)">
              Delete Signal
            </button>
            <ng-template #confirmDeleteSignal>
              <div class="modal-body text-center">
                <p>Do you want to delete this signal?</p>
                <button type="button" class="btn btn-default" (click)="modalRef.hide()">No</button>
                <button type="button" class="btn btn-primary" (click)="DeleteSignal()">Yes</button>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </tab>

    <!-- Signal Event tab -->
    <tab heading="Initial or Follow-up">
      <div style="width: 40%" class="contentTabBorder">
        <form (submit)="ValSignalEventDateInitialOrFollowUpForm()">
          <!-- EPITT -->
          <div class="form-group">
            <label for="SignalEventEPITT">EPITT:</label>
            <input type="text" class="form-control" name="signalevent-epitt" id="SignalEventEPITT" [maxLength]="5"
                   placeholder="EPITT" [(ngModel)]="SignalEventEPITT" autocomplete="off">
          </div>
          <alert type="danger" class="{{validSignalEventEPITT}}">
            <strong>Required!</strong> You must provide a valid EPITT.
          </alert>
          <alert type="danger" class="{{validSignalEventIsInitial}}">
            <strong>Invalid input!</strong> For this EPITT an Initial already exists.
          </alert>

          <!-- Initial or Follow-up -->
          <fieldset class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-2 pt-0">Type:</legend>
              <div class="col-sm-10">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="signal-source" id="SignalEventTypeInitial"
                         [value]="0" [(ngModel)]="SignalEventType">
                  <label class="custom-control-label" for="SignalEventTypeInitial">Initial</label>
                </div>
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="signal-source" id="SignalEventTypeFollowUp"
                         [value]="1" [(ngModel)]="SignalEventType">
                  <label class="custom-control-label" for="SignalEventTypeFollowUp">Follow-up</label>
                </div>
              </div>
            </div>
          </fieldset>

          <!-- Is final -->
          Is final:
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="SignalEventIsFinal" [(ngModel)]="SignalEventIsFinal"
                   name="signalevent-isfinal">
            <label class="custom-control-label" for="SignalEventIsFinal">Recommendation is final for this signal</label>
          </div>

          <!-- Date -->
          <div class="form-group">
            <label for="SignalEventDate">Date:</label>
            <br>
            <div class="input-group">
              <input class="form-control" placeholder="DD.MM.YYYY" name="signalevent-date" [(ngModel)]="SignalEventDate"
                     value="{{ SignalEventFormattedDate | date:'dd.MM.yyyy' }}" [(bsValue)]="SignalEventFormattedDate"
                     id="SignalEventDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }" autocomplete="off">
            </div>
          </div>
          <alert type="danger" class="{{validSignalEventDate}}">
            <strong>Required!</strong> You must provide a valid date for this signal event.
          </alert>
          <alert type="danger" class="{{validRecommendationSignalEvent}}">
            <strong>Duplicate found!</strong> This signal event already exists.
          </alert>
          <alert type="danger" class="{{validRecommendationDeleteSignalEvent}}">
            <strong>Signal Event not found!</strong> A SignalEvent at this date for this EPITT doesn't exist.
          </alert>
          <alert type="danger" class="{{validRecommendationSignalEventDate}}">
            <strong>Inconsistency found!</strong> This FollowUp precedes the Initial for this Signal.
          </alert>

          <!-- Recommendation(s) -->
          Recommendations:
          <div class="form-group">
            <div class="custom-control custom-checkbox" *ngFor="let recommendation of roptions; let i = index">
              <input class="custom-control-input" type="checkbox" id="SignalEventRecommendation_{{i}}" [(ngModel)]="rchecks[i]"
              name="signalevent[recommendations_{{i}}]">
              <label class="custom-control-label" for="SignalEventRecommendation_{{i}}">{{recommendation['PRAC_Signal_Recommendation']}}</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="SignalEventOtherRecommendation" name="signalevent-otherrecommendation"
                     [(ngModel)]="SignalEventOtherRecommendationChecked">
              <label class="custom-control-label" for="SignalEventOtherRecommendation">Other Recommendation:</label>
              <br>
              <input type="text" name="signalevent-otherrecommendationtext" id="SignalEventOtherRecommendationText"
                     [(ngModel)]="SignalEventOtherRecommendationText" class="form-control" placeholder="Other Recommendation">
            </div>
          </div>

          <!-- Comments -->
          <div class="form-group">
            <label for="SignalEventComments">Comments:</label>
            <textarea class="form-control" rows="3" id="SignalEventComments" name="SignalEventComments"
                      placeholder="Comments" [(ngModel)]="SignalEventComments"></textarea>
          </div>

          <!-- Buttons -->
          <div>
            <button type="button" class="btn btn-outline-secondary form-button" (click)="ClearSignalEventForm()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary form-button">
              {{SignalEventButtonCaption}}
            </button>
            <button type="button" class="btn btn-outline-danger form-button" (click)="DeleteSignalEvent()">
              Delete Signal Event
            </button>
          </div>
        </form>
      </div>
    </tab>
    <tab heading="Rapporteur Country">
    </tab>
    <tab heading="Drug Product">
      <app-edit-drug-product></app-edit-drug-product>
    </tab>
    <tab heading="Modify Settings">
      <div style="width: 40%" class="contentTabBorder">
        <form (submit)="ChangeDatabaseSettings()">
          <!-- Last updated -->
          <div class="form-group">
            <label for="SettingsLastModifiedDate">Last database update:</label>
            <br>
            <div class="input-group">
              <input class="form-control" placeholder="DD.MM.YYYY" name="settings-last-modified-date" [(ngModel)]="SettingsLastModifiedDate"
                     value="{{ SettingsLastModifiedFormattedDate | date:'dd.MM.yyyy' }}" [(bsValue)]="SettingsLastModifiedFormattedDate"
                     id="SettingsLastModifiedDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }" autocomplete="off">
            </div>
          </div>

          <!-- Last PRAC meeting -->
          <div class="form-group">
            <label for="SettingsLatestRecommendationDate">Latest signal recommendation date:</label>
            <br>
            <div class="input-group">
              <input class="form-control" placeholder="DD.MM.YYYY" name="settings-latest-recommendation-date"
                     [(ngModel)]="SettingsLatestRecommendationDate"
                     value="{{ SettingsLatestRecommendationFormattedDate | date:'dd.MM.yyyy' }}"
                     [(bsValue)]="SettingsLatestRecommendationFormattedDate"
                     id="SettingsLatestRecommendationDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }"
                     autocomplete="off">
            </div>
          </div>

          <!-- Database version -->

          <!-- Buttons -->
          <div>
            <button type="button" class="btn btn-outline-secondary form-button" (click)="ClearDatabaseSettingsForm()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary form-button">
              Update Settings
            </button>
          </div>
        </form>
      </div>
    </tab>
  </tabset>
</div>

<ng-template #notLoggedIn>
  <app-login-status></app-login-status>
</ng-template>
