import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthorizationState, projectGetScriptPath, projectSetScriptPath } from '../../global-constants';

@Component({
  selector: 'app-therapeuticclass-wiki',
  templateUrl: './therapeuticclass-wiki.component.html',
  styleUrls: ['./therapeuticclass-wiki.component.css']
})
export class TherapeuticclassWikiComponent implements OnInit {
  ClassID: any;
  ClassName: any;
  Signals: any;
  Substances: any; // Substances already assigned to this class
  selectedActiveSubstances: any; // The ones to be submitted for adding them to this class
  allActiveSubstances: any; // Array of all available active substances
  displayAllActiveSubstances = []; // Array to show the (filtered) list of all active substances
  ActiveSubstancesFilterTerm: string;
  AddNewSubstanceAndAssignToClassModalRef: BsModalRef;
  NewSubstanceINN: string;

  constructor(private http: HttpClient, private router: Router, private modalService: BsModalService) { }

  getClass(): string {
    return this.router.url.split('/')[3];
  }

  loadClassByID(tcID): void {
    const body = {
      GetTherapeuticClassByID: '',
      ClassID: tcID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.ClassName = response.Description_EN;
      this.ClassID = response.TC_ID;
    });
  }

  loadSignalsByTherapeuticClassID(classID): void {
    const body = {
      GetSignalsByTherapeuticClassID: '',
      ClassID: classID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.Signals = response;

      for (const item of this.Signals) {
        let isFinal = false;
        for (const se of item.SignalEvents) {
          if (se.IsFinal === '1') {
            isFinal = true;
          }
        }
        item.IsFinal = isFinal;
      }
    });
  }

  loadSubstancesByTherapeuticClassID(tcid): void {
    const body = {
      GetSubstancesByTherapeuticClassID: '',
      ClassID: tcid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.Substances = response;
      this.selectedActiveSubstances = [];
      for (const item of this.Substances) {
        this.selectedActiveSubstances.push(item.INN_EN);
      }
    });
  }

  getAllActiveSubstances(tcid): void {
    const body = {
      GetAllActiveSubstancesNotInTherapeuticClass: '',
      TherapeuticClassID: tcid
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.allActiveSubstances = [];

      for (const item of response) {
        this.allActiveSubstances.push(item.INN_EN);
      }
    });
    this.displayAllActiveSubstances = this.allActiveSubstances;
  }

  addSubstancesToClass(): void {
    const body = {
      AddActiveSubstanceToClass: '',
      ClassID: this.ClassID,
      Substances: this.selectedActiveSubstances
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
      this.selectedActiveSubstances = null;
      this.loadSubstancesByTherapeuticClassID(this.ClassID);
      this.getAllActiveSubstances(this.ClassID);
    });
  }

  getSubstancesRemovedFromClass(): any {
    const removedActiveSubstances = [];
    for (const item of this.Substances) {
      if ((this.selectedActiveSubstances.indexOf(item.INN_EN) === -1) && (this.allActiveSubstances.indexOf(item.INN_EN) === -1)) {
        console.log(item.INN_EN);
        removedActiveSubstances.push(item);
      }
    }
    return removedActiveSubstances;
  }

  filterAllActiveSubstances(): void {
    this.displayAllActiveSubstances = this.allActiveSubstances.filter(obj =>
      obj.toLowerCase().indexOf(this.ActiveSubstancesFilterTerm.toLowerCase()) >= 0);
  }

  setClassByID(tcid): void {
   this.loadClassByID(tcid);
   this.loadSignalsByTherapeuticClassID(tcid);
   this.loadSubstancesByTherapeuticClassID(tcid);
   this.getAllActiveSubstances(tcid);
  }

  ngOnInit(): void {
    this.loadClassByID(this.getClass());
    this.loadSignalsByTherapeuticClassID(this.getClass());
    this.loadSubstancesByTherapeuticClassID(this.getClass());
    this.getAllActiveSubstances(this.getClass());
  }

  IsLoggedInAsAdmin(): boolean {
    return AuthorizationState.UserIsAdmin();
  }

  OpenAddAndAssignNewSubstanceModal(template: TemplateRef<any>): void {
    this.AddNewSubstanceAndAssignToClassModalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  AddNewSubstanceAndAssignToClass(): void {
    const body = {
      AddSubstanceAndAssignToClass: '',
      NewSubstanceINN: this.NewSubstanceINN,
      ClassID: this.ClassID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body, {headers}).subscribe();

    this.AddNewSubstanceAndAssignToClassModalRef.hide();
    this.loadClassByID(this.ClassID);
    this.NewSubstanceINN = '';
  }
}
