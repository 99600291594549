<div style="margin-left: 10px" class="mainContent">
  <h2>{{DrugProductName}}</h2>
  <div style="color: #4e555b; font-size: large; margin-top: -4pt">
    <!--Brand name-->
    {{DrugProductMAH}}
  </div>
  <hr>

  <table class="statisticsTable">
    <tr class="value">
      <!--<td>
        {{DrugProductMAH}}
      </td>-->
      <td class="blue">
        <a [href]="DrugProductEMALink" target="_blank">{{DrugProductEUNumber}}</a>
      </td>
      <td class="green">{{DrugProductStatus}}</td>
      <td class="purple">{{DrugProductAuthorisationDate}}</td>
      <td *ngIf="DrugProductOrphanDesignationCount > 0; else noOrphan">OMP</td>
      <ng-template #noOrphan><td class="red">No OMP</td></ng-template>
    </tr>
    <tr class="label">
     <!-- <td>MAH</td>-->
      <td>EU-NUMBER</td>
      <td>STATUS</td>
      <td>AUTHORISATION DATE</td>
      <td>ORPHAN STATUS</td>
    </tr>
  </table>
  <hr>

  <h4>Indications</h4>
  <div>
    {{DrugProductIndications}}
  </div>
  <hr>

  <!--
  <h4>Active Substances</h4>
  <div class="col">
    <div class="row">
      <div *ngIf="DrugProductActiveSubstances.length === 0">
        <i>No active substances have been assigned to this drug product.</i>
      </div>
      <div class="list-group">
        <a class="list-group-item list-group-item-action" *ngFor="let ActiveSubstance of DrugProductActiveSubstances"
           [routerLink]="['../../substance/', ActiveSubstance['TC_ID']]">{{ActiveSubstance['IIN_EN']}}</a>
      </div>
    </div>
  </div>
  -->

  <!--
  <hr>
  <h4>Signals</h4>
  <app-small-signal-table [(ngModel)]="DrugProductSignals"></app-small-signal-table>
  -->

  <!--
  <h5>Related Signals</h5>
  <div *ngIf="RelatedSignals !== null; else noRelatedSignals">
    <div *ngIf="RelatedSignals.length > 0; else noRelatedSignals">
      <app-small-signal-table [(ngModel)]="RelatedSignals"></app-small-signal-table>
    </div>
  </div>
  <ng-template #noRelatedSignals>
    <div style="font-style: italic">
      No related Signals were found for this substance.
    </div>
  </ng-template>
  -->

  <!--
  <hr>
  <h4>Related Drug Products</h4>
  <div *ngIf="RelatedSubstances.length === 0">
    <i>No related substances were found.</i>
  </div>
  <div class="row">
    <div class="col">
      <div class="list-group">
        <a *ngFor="let substance of RelatedSubstances" class="list-group-item list-group-item-action"
           (click)="setSubstanceByID(substance['AI_ID'])"  [routerLink]="['../', substance['AI_ID']]">
          <img src="https://vfa-signals.de/media/molecule.png" alt="Molecule placeholder icon" class="img-fluid" width="25px"
               style="margin-right: 5px">
          {{substance['INN_EN']}}
        </a>
      </div>
    </div>
    <div class="col"></div>
  </div>

  <hr>
  <h4>Drug Products</h4>
  <span *ngIf="DrugProducts.length === 0; else showTable" style="font-style: italic">
    No Drug Products are related to this substance.
  </span>
  <ng-template #showTable>
    <table class="table" style="width: 45%">
      <thead>
      <tr>
        <th>Name</th>
        <th>Substances</th>
        <th>MAH</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let DrugProduct of DrugProducts">
        <td *ngIf="IsLoggedIn() else name">
          <a [routerLink]="['../../../edit-db/drug-product:' + DrugProduct['Drug_Product']['DP_ID']]">
            {{DrugProduct['Drug_Product']['EU_Name']}}
          </a>
        </td>
        <ng-template #name>
          <td>{{DrugProduct['Drug_Product']['EU_Name']}}</td>
        </ng-template>
        <td>
          <a *ngFor="let Substance of DrugProduct['Substances']" (click)="setSubstanceByID(Substance['AI_ID'])" [routerLink]="['../../substances/', Substance['AI_ID']]">
            {{Substance['INN_EN']}} <br>
          </a>
        </td>
        <td>{{DrugProduct['Drug_Product']['MAH']}}</td>
      </tr>
      </tbody>
    </table>
  </ng-template>

  <div *ngIf="IsLoggedIn()">
    <h4>Actions</h4>
    <ng-template #renameSubstanceModal>
      <div class="modal-body text-center">
        <p>Rename active substance nr. {{SubstanceID}}</p>
        <input type="text" [(ngModel)]="RenameINN">
        <br><br>
        <button type="button" class="btn btn-default" (click)="this.renameCurrentSubstance()">Rename</button>
        <button type="button" class="btn btn-default" (click)="this.RenameSubstanceModalRef.hide()">Cancel</button>
      </div>
    </ng-template>
    <div class="btn-group" role="group" aria-label="Button Group" style="color: white">
      <a type="button" class="btn btn-primary" (click)="openRenameSubstanceModal(renameSubstanceModal)">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Rename Substance
      </a>

      <button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-square" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Add Substance to Class(es)
      </button>

      <ng-template #deleteSubstanceModal>
        <div class="modal-body text-center">
          <p>Do you want to delete this substance?</p>
          <button type="button" class="btn btn-default" (click)="deleteCurrentSubstance()">Yes</button>
          <button type="button" class="btn btn-default" (click)="DeleteSubstanceModalRef.hide()">No</button>
        </div>
      </ng-template>
      <button type="button" class="btn btn-primary" (click)="openDeleteCurrentSubstanceModal(deleteSubstanceModal)">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
        </svg>
        Delete Substance
      </button>
    </div>
  </div>-->
</div>
