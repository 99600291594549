<div class="mainContent">
  <tabset>
    <tab heading="Signals">
      <app-overview-on-signals></app-overview-on-signals>
    </tab>
    <tab heading="Outcomes">
      <app-final-outcomes></app-final-outcomes>
    </tab>
    <tab heading="Rapporteurs">
      <app-rapporteurs-by-year></app-rapporteurs-by-year>
    </tab>
    <tab *ngIf="IsLoggedInAsAdmin" heading="Users">
      <app-users-chart></app-users-chart>
    </tab>
  </tabset>
</div>
