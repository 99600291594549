import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-small-signal-table',
  templateUrl: './small-signal-table.component.html',
  styleUrls: ['./small-signal-table.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmallSignalTableComponent),
      multi: true
    }
  ]
})

export class SmallSignalTableComponent implements OnInit, ControlValueAccessor {

  @Input() private _Signals: any;
  tablePage = 1;
  SignalCountPerPage = 5;
  displayedSignals: any;

  get Signals(): any {
    return this._Signals;
  }

  set Signals(value: any) {
    this._Signals = value;
    this.propagateChange(this._Signals);
  }

  constructor() { }

  propagateChange = (_: any) => {};

  ngOnInit(): void {
    this.propagateChange(this._Signals);
  }

  registerOnChange(fn: any): void {
    this.propagateChange(fn);
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this._Signals = obj;
    }
  }

}
