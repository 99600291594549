import { Component, OnInit } from '@angular/core';
import { SignalClass } from '../../shared/classes/signal-class';
import { projectGetScriptPath } from '../../global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-signals-by-meetings',
  templateUrl: './signals-by-meetings.component.html',
  styleUrls: ['./signals-by-meetings.component.css']
})
export class SignalsByMeetingsComponent implements OnInit {
  resultCount = 5;
  resultPage = 0;
  PageCount = 0;
  signalCount = 5;
  meetingYear = 2020;
  meetingMonth = 5;
  meetingMonthString = 'January';
  possibleYears = [];
  signals: SignalClass[];
  PracMeetingList;

  PracMeetingDate = '';
  PracMeetingFormattedDate: Date;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getYears();
    this.getPRAC_MeetingDates();
  }

  setResultCount(count): void {
    this.resultCount = count;
  }

  readMeetingDateFromDateTimePicker(): void {
    const theDate = this.PracMeetingFormattedDate;
    const options1 = { month: '2-digit' } as const;
    this.meetingMonth =  +theDate.toLocaleString('de-DE', options1);

    const options2 = { year: 'numeric' } as const;
    this.meetingYear =  +theDate.toLocaleString('de-DE', options2);
  }

  getPRAC_MeetingDates(): void {
    const body = {
      GetPracMeetingDates: '',
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });

    this.http.post<any>(projectGetScriptPath, body, {headers}).subscribe((response) => {
      this.PracMeetingList = response;
      this.meetingYear = +this.PracMeetingList[0].MeetingDate.substring(0, 4);
      this.meetingMonth = +this.PracMeetingList[0].MeetingDate.substring(5, 7);
      this.meetingMonthString = this.MeetingMonthToString(this.meetingMonth);
      this.RefreshSearchResults();
    });
  }

  AuthorizationTypeToString(signal): string {
    if ((signal.isNAP === '1') && (signal.isCAP === '1')) {
      return 'CAP & NAP';
    } else if (signal.isCAP === '1') {
      return 'CAP';
    } else {
      return 'NAP';
    }
  }

  RefreshSearchResults(): void {
    this.GetSignalsByRange(this.meetingMonth, this.meetingYear).subscribe((response) => {
      this.signals = response;
    });
    this.GetSignalCount(this.meetingMonth, this.meetingYear).subscribe((response) => {
      this.signalCount = +response['COUNT(*)'];
      console.log(response);
    });
    this.PageCount = this.GetPageCount();
    this.resultPage = 1;
  }

  GetSignalCount(month, year): any {
    const body = {
      GetSignalsCountByMeeting: '',
      PracMeetingMonth: month,
      PracMeetingYear: year,
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });

    return this.http.post(projectGetScriptPath, body,
      {headers});
  }

  GetPageCount(): number {
    return Math.ceil(this.signalCount / this.resultCount);
  }

  GetSignalsByRange(month, year): any {
    const body = {
      GetSignalsByPracMeetingAndRange: '',
      PracMeetingMonth: month,
      PracMeetingYear: year,
      StartCount: (this.resultPage - 1) * this.resultCount,
      ResultCount: this.resultCount
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });
    return this.http.post<SignalClass[]>(projectGetScriptPath, body,
      {headers});
  }

  changeMeetingMonth(month: number): void {
    this.meetingMonth = month;
    this.meetingMonthString = this.MeetingMonthToString(month);
    this.RefreshSearchResults();
  }

  MeetingMonthToString(month: number): string {
    let result = '';

    switch (month) {
      case 1: result = 'January'; break;
      case 2: result = 'February'; break;
      case 3: result = 'March'; break;
      case 4: result = 'April'; break;
      case 5: result = 'May'; break;
      case 6: result = 'June'; break;
      case 7: result = 'July'; break;
      case 8: result = 'August'; break;
      case 9: result = 'September'; break;
      case 10: result = 'October'; break;
      case 11: result = 'November'; break;
      case 12: result = 'December'; break;
    }

    return result;
  }

  changeMeetingYear(year: number): void {
    this.meetingYear = year;
    this.RefreshSearchResults();
  }

  getYears(): void {
    const body = {
      GetMinMaxSignalDates: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      const minYear = new Date(response['MIN(SignalEvent.Date)']);
      const maxYear = new Date(response['MAX(SignalEvent.Date)']);
      for (let i = minYear.getFullYear(); i <= maxYear.getFullYear(); i++) {
        this.possibleYears.push(i);
      }
    });
  }
}
