<div>
  <table class="table table-hover" style="width: 70%">
    <thead>
    <tr>
      <th scope="col">EPITT</th>
      <th scope="col">Substances</th>
      <th scope="col">Term</th>
      <th scope="col">Final Outcome</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let i of Signals.slice(SignalCountPerPage * (tablePage - 1), SignalCountPerPage * (tablePage - 1) + SignalCountPerPage)">
      <td><a [routerLink]="['../../signal/', i['Signal']['EPITT']]">{{i['Signal']['EPITT']}}</a></td>
      <td>
        <span *ngFor="let substances of i['Substances']">
          <a [routerLink]="['../../substance/', substances['AI_ID']]">{{substances['INN_EN']}}</a>
          <br>
        </span>
        <span *ngFor="let classes of i['TherapeuticClasses']">
            <a [routerLink]="['../../therapeutic-class/', classes['TC_ID']]">{{classes['Description_EN']}}</a>
          <br>
        </span>
      </td>
      <td>{{i['Signal']['Term']}}</td>
      <td *ngIf="i['IsFinal'] else ongoing" style="font-weight: bold">
        <div class="ui list">
          <div *ngFor="let se of i['SignalEvents']">
            <div *ngIf="se['IsFinal'] === '1'">
              <div *ngFor="let rec of se['Recommendations']">
                {{rec['Final_outcome']}}
              </div>
              <div *ngIf="se['OtherRecommendation'] !== ''">
                {{se['OtherRecommendation']}}
              </div>
            </div>
          </div>
          <div class="item"></div>
        </div>
      </td>
      <ng-template #ongoing>
        <td style="font-weight: bold; color: #660000">
          Ongoing
        </td>
      </ng-template>
    </tr>
    </tbody>
  </table>

  <pagination [totalItems]="Signals.length" [(ngModel)]="tablePage" [maxSize]="10" [itemsPerPage]="SignalCountPerPage"></pagination>
</div>
