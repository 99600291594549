import { Workbook } from 'exceljs';
import {
  AuthorizationTypeToString,
  GetFinalOutcomeAsString,
  GetRapporteursAsString,
  RecommendationsToString,
  SubstancesAndClassesToString
} from '../classes/signal-class';
import * as fs from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export async function ExportSignalsAsExcel(signals, filename, httpClient: HttpClient): Promise<void> {
  const workbook = new Workbook();
  await AddDisclaimerWorksheet(workbook, httpClient);
  const worksheet = workbook.addWorksheet('PRAC_Signals');

  // Assignment
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getRow(1).font = {
    bold: true,
    color: { argb: '0482C3' }
  };
  worksheet.getCell('A1').value = 'Signal Details';
  worksheet.mergeCells('A1:D1');
  worksheet.getCell('E1').value = 'Additional Information';
  worksheet.mergeCells('E1:G1');
  worksheet.getCell('H1').value = 'Initial';
  worksheet.mergeCells('H1:J1');
  worksheet.getCell('K1').value = 'Follow-Up 1';
  worksheet.mergeCells('K1:M1');
  worksheet.getCell('N1').value = 'Follow-Up 2';
  worksheet.mergeCells('N1:P1');
  worksheet.getCell('Q1').value = 'Follow-Up 3';
  worksheet.mergeCells('Q1:S1');
  worksheet.getCell('T1').value = 'Follow-Up 4';
  worksheet.mergeCells('T1:V1');
  worksheet.getCell('W1').value = 'Follow-Up 5';
  worksheet.mergeCells('W1:Y1');

  worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getRow(2).font = {
    color: { argb: 'FFFFFF' }
  };
  worksheet.getRow(2).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '0482C3' },
    bgColor: { argb: '' }
  };

  worksheet.getCell('A2').value = 'EPITT';
  worksheet.getCell('B2').value = 'Term';
  worksheet.getCell('C2').value = 'Drugs';
  worksheet.getCell('D2').value = 'Final Outcome';
  worksheet.getCell('E2').value = 'Source';
  worksheet.getCell('F2').value = 'Rapporteur';
  worksheet.getCell('G2').value = 'Authorization Type';

  // Initial
  worksheet.getCell('H2').value = 'Date';
  worksheet.getCell('I2').value = 'Recommendation';
  worksheet.getCell('J2').value = 'Comment';

  // Follow-Up 1
  worksheet.getCell('K2').value = 'Date';
  worksheet.getCell('L2').value = 'Recommendation';
  worksheet.getCell('M2').value = 'Comment';

  // Follow-Up 2
  worksheet.getCell('N2').value = 'Date';
  worksheet.getCell('O2').value = 'Recommendation';
  worksheet.getCell('P2').value = 'Comment';

  // Follow-Up 3
  worksheet.getCell('Q2').value = 'Date';
  worksheet.getCell('R2').value = 'Recommendation';
  worksheet.getCell('S2').value = 'Comment';

  // Follow-Up 4
  worksheet.getCell('T2').value = 'Date';
  worksheet.getCell('U2').value = 'Recommendation';
  worksheet.getCell('V2').value = 'Comment';

  // Follow-Up 5
  worksheet.getCell('W2').value = 'Date';
  worksheet.getCell('X2').value = 'Recommendation';
  worksheet.getCell('Y2').value = 'Comment';

  for (let i = 1; i <= signals.length; i++) {
    const index = i - 1;
    worksheet.getCell(i + 2, 1).value = signals[index].epitt;
    worksheet.getCell(i + 2, 2).value = signals[index].term;
    worksheet.getCell(i + 2, 3).value = SubstancesAndClassesToString(signals[index].substances, signals[index].therapeuticClasses);
    worksheet.getCell(i + 2, 4).value = GetFinalOutcomeAsString(signals[index]);
    worksheet.getCell(i + 2, 5).value = signals[index].source;
    worksheet.getCell(i + 2, 6).value = GetRapporteursAsString(signals[index]);
    worksheet.getCell(i + 2, 7).value = AuthorizationTypeToString(signals[index]);

    let SEcounter = 1;
    for (const signalEvent of signals[index].signalEvents) {

      if (signalEvent.IsFollowUp === '0') {
        worksheet.getCell(i + 2, 8).value = signalEvent.FDate;
        worksheet.getCell(i + 2, 9).value = RecommendationsToString(signalEvent.Recommendations);
        worksheet.getCell(i + 2, 10).value = signalEvent.Comments;
      } else {
        worksheet.getCell(i + 2, 8 + 3 * SEcounter).value = signalEvent.FDate;
        worksheet.getCell(i + 2, 9 + 3 * SEcounter).value = RecommendationsToString(signalEvent.Recommendations);
        worksheet.getCell(i + 2, 10 + 3 * SEcounter).value = signalEvent.Comments;
        SEcounter = SEcounter + 1;
      }
    }
  }

  // Export
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, filename + '.xlsx');
  });
}

async function AddDisclaimerWorksheet(workbook: Workbook, httpClient: HttpClient): Promise<void> {
  workbook.addWorksheet('Disclaimer');
  workbook.getWorksheet('Disclaimer').getCell('A1').value = 'Disclaimer';
  workbook.getWorksheet('Disclaimer').getCell('A1').font = {
    size: 16
  };

  const headers = new HttpHeaders(
    {
      'Content-Type': 'text/plain',
    });

  workbook.getWorksheet('Disclaimer').getCell(3, 1).value = await httpClient.get('https://www.vfa-signals.de/media/files/disclaimer.txt',
    {headers, responseType: 'text'}).toPromise();
  workbook.getWorksheet('Disclaimer').getCell(3, 1).alignment = {
    wrapText: true
  };
  workbook.getWorksheet('Disclaimer').getRow(3).height = 180;
  workbook.getWorksheet('Disclaimer').getColumn(1).width = 250;
}

export async function ExportSubstancesNetworksAsExcel(network, filename, httpClient: HttpClient): Promise<void> {
  const workbook = new Workbook();
  await AddDisclaimerWorksheet(workbook, httpClient);
  const worksheet = workbook.addWorksheet('Network');

  console.log(network.length);

  // Assignment
  worksheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
  worksheet.getCell('A1').value = 'Substance_1_ID';
  worksheet.getCell('B1').value = 'Substance_1_INN';
  worksheet.getCell('C1').value = 'Substance_2_ID';
  worksheet.getCell('D1').value = 'Substance_2_INN';

  // Fill with data
  let counter = 2;
  for (const item of network) {
    console.log(item);
    worksheet.getCell(counter, 1).value = item[0];
    worksheet.getCell(counter, 2).value = item[1];
    worksheet.getCell(counter, 3).value = item[2];
    worksheet.getCell(counter, 4).value = item[3];

    counter++;
  }

  // Export
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, filename + '.xlsx');
  });
}
