<div style="display: block; width: 75%" class="contentTabBorder">
  <h2>Overview on Signals</h2>
  <canvas baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors">
  </canvas>
</div>
