<div class="contentTabBorder">
  <h2>Rapporteurs by Year</h2>
  <div class="row" style="margin-left: 0.5%">
    <div class="col-8" style="display:block;">
      <div class="row" style="margin-bottom: 1%">
        <!-- YEAR SELECT -->
        <div class="btn-group col-md-3" dropdown>
          <button id="RapporteursByYearSelectButton" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                  aria-controls="dropdown-basic" [(ngModel)]="selectedYear" (ngModelChange)="changeYear()" name="year-select-btn">
            {{selectedYear}}<span class="caret"></span>
          </button>
          <ul id="RapporteursByYearSelectItems" *dropdownMenu class="dropdown-menu" role="menu"
              aria-labelledby="SignalOverviewSelectButton">
            <li *ngFor="let o of possibleYears" role="menuitem" (click)="selectedYear = o; changeYear()"><a class="dropdown-item">{{o}}</a></li>
          </ul>
        </div>
      </div>
      <div class="row" style="display: block">
        <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [colors]="barChartColors"
                [chartType]="barChartType">
        </canvas>
      </div>
    </div>
    <div class="col-3">
      <accordion [isAnimated]="true">
        <accordion-group heading="Country Abbreviations">
          <table class="table">
            <thead>
            <tr>
              <th>ID</th>
              <th>Country</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/0']">tba</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/0']">to be announced</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/7']">AT</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/7']">Austria</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/8']">BE</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/8']">Belgium</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/12']">CY</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/12']">Cyprus</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/13']">CZ</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/13']">Czechia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/4']">DE</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/4']">Germany</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/14']">DK</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/14']">Denmark</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/16']">EE</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/16']">Estonia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/31']">EL</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/31']">Greece</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/28']">ES</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/28']">Spain</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/15']">FI</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/15']">Finland</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/17']">FR</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/17']">France</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/18']">HR</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/18']">Croatia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/11']">HU</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/11']">Hungary</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/19']">IE</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/19']">Ireland</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/20']">IT</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/20']">Italy</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/22']">LV</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/22']">Latvia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/23']">NL</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/23']">Netherlands</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/24']">NO</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/24']">Norway</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/25']">PL</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/25']">Poland</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/26']">PT</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/26']">Portugal</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/29']">SE</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/26']">Sweden</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/32']">SI</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/32']">Slovenia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/27']">SK</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/27']">Slovakia</a></td>
            </tr>
            <tr>
              <td><a [routerLink]="['../wiki/rapporteur/30']">UK</a></td>
              <td><a [routerLink]="['../wiki/rapporteur/30']">United Kingdom</a></td>
            </tr>
            </tbody>
          </table>
        </accordion-group>
      </accordion>
    </div>
  </div>
</div>
