<div class="mainContent">
  <h2>About the vfa signal database</h2>
  <div class="contentTextDivBox">
    <h3>Introduction</h3>
    <p>
      The interactive database of PRAC signals is a service provided by vfa - the German Association of Research-based
      Pharmaceutical Companies (<a href="https://www.vfa.de" target="_blank">www.vfa.de</a>). Its data basis is maintained
      continuously. However, we do not assume any liability for the information provided. Please always refer to the
      official documents (<a>PRAC meeting minutes</a>, <a>PRAC recommendations on safety signals</a> and the <a>list of
      safety signals discussed since September 2012</a>) by the EMA
      (<a href="https://www.ema.europa.eu/en/human-regulatory/post-authorisation/pharmacovigilance/signal-management"
          target="_blank">www.ema.europa.eu
      <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
      </svg>
      </a>).
    </p>
    <p>
      We always look for options to improve our service. For feedback, questions on signal management, or technical problems
      do not hesitate to contact our team.
    </p>
  </div>
  <div>
    <h3 id="team">Project Team</h3>
    <div>
      <!-- Judith -->
      <div class="card" style="width: 20rem">
        <img src="https://vfa-signals.de/media/jw.jpg" class="card-img-top" alt="Image Jusith Weigel">
        <div class="card-body">
          <h5 class="card-title">Judith Weigel</h5>
          <p class="card-text">The trained pharmacist Judith is Pharmacovigilance Manager at vfa and responsible for the database content.</p>
          Mail:
          <a href="mailto:j.weigel@vfa.de" class="card-link" style="vertical-align: center">
            j.weigel@vfa.de
          </a>
        </div>
      </div>

      <!-- Marc -->
      <div class="card" style="width: 20rem">
        <img src="https://vfa-signals.de/media/ma.jpg" class="card-img-top" alt="Image Marc Appis">
        <div class="card-body">
          <h5 class="card-title">Marc Appis</h5>
          <p class="card-text">
            Marc is student assistant in the FEI department at vfa and manages the technical operations of the database.
          </p>
          <div>
            <!--<svg width="1.1em" height="1.1em" viewBox="0 0 16 16" class="bi bi-envelope-fill" fill="blue" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>-->
            Mail:
            <a href="mailto:m.appis@vfa.de" class="card-link">
              m.appis@vfa.de
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contentTextDivBox">
    <h3>Disclaimer</h3>
    <p>
      The interactive database provided here is a service provided to you by vfa - Verband Forschender Arzneimittelhersteller e.V.
      (German Association of Research-based Pharmaceutical Companies,
      <a href="https://www.vfa.de" target="_blank">
        www.vfa.de
        <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
          <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
        </svg>
      </a>). The data compiled in this database are extracted from the regular meetings of the EMA's Pharmacovigilance
      Risk Assessment Committee (PRAC). The PRAC regularly publishes the results of the signal assessments on the EMA
      website in form of meeting minutes (<a
      href="https://www.ema.europa.eu/en/committees/prac/prac-agendas-minutes-highlights#minutes-section" target="_blank">Link
        <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
          <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
        </svg>
      </a>) and signal recommendations (<a
          href="https://www.ema.europa.eu/en/human-regulatory/post-authorisation/pharmacovigilance/signal-management/prac-recommendations-safety-signals"
          target="_blank">Link
        <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
          <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
        </svg>
      </a>).
    </p>
    <p>
      Please note that the vfa can only compile and update the database based on the published PRAC meeting minutes and
      recommendations on safety signals. The PRAC itself points out in a disclaimer at the beginning of its published
      meeting minutes that the information recorded is in part not conclusive, may not always reflect the full facts and
      may be updated subsequently (e.g., in the case of confidential data, changing factors or only sketchy presentations
      of the underlying data). In this regard, please also note the information provided by the PRAC itself.
    </p>
    <p>
      Incomplete or subsequently changing information may also affect the content of this database. We therefore
      recommend that you always consult the official documents published on the EMA website and compare the information
      accordingly
      (<a href="https://www.ema.europa.eu" target="_blank">www.ema.europa.eu
        <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-box-arrow-up-right" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
          <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
        </svg>
      </a>).
    </p>
    <p>
      The vfa assumes no liability for the topicality, correctness and completeness of the information provided in the
      signal database. Liability claims against the vfa relating to material or non-material damage caused by the use or
      non-use of the information provided or by the use of incorrect or incomplete information are excluded, unless the
      vfa can be proven to have acted with intent or gross negligence. Parts of the pages or the complete publication
      including all offers and information might be extended, changed, or partly or completely deleted by the vfa without
      separate announcement.
    </p>
    <p>
      Do you have any questions, suggestions for improving the service, requests, feedback, or technical problems? We
      would be very happy to receive your feedback and are at your disposal.
    </p>
  </div>
</div>
