<div class="withborder">
  <h2>Cumulative List of PRAC Signals</h2>
  <div>
    <div class="row" style="margin-left: 0.5%; margin-top: 1%; margin-bottom: 1%; width: 95%">
      <div>
        <pagination [totalItems]="signalCount" [(ngModel)]="resultPage" (ngModelChange)="RefreshSearchResults()"
        [maxSize]="6" [boundaryLinks]="true" [itemsPerPage]="resultCount"></pagination>
      </div>

      <div style="float: left; margin-left: 1%">
        <!-- Choose number of rows -->
        <div class="btn-group" dropdown>
          <button id="SignalOverviewRowSelectButton" dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn"
                  aria-controls="dropdown-basic">
            Result count per page: {{resultCount}} <span class="caret"></span>
          </button>
          <ul id="SignalOverviewRowSelectItems" *dropdownMenu class="dropdown-menu" role="menu"
              aria-labelledby="SignalOverviewRowSelectButton">
            <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(5)">5</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(10)">10</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(25)">25</a></li>
            <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(50)">50</a></li>
          </ul>
        </div>
      </div>

      <div class="form-group row" style="margin-left: 1%">
        <!-- Filter by EPITT -->
        <div class="input-group mb3" style="width: 200px; float: start;">
          <span class="input-group-text" id="filter-epitt-addon">EPITT: </span>
          <input type="text" class="form-control" aria-label="EPITT" [(ngModel)]="selectedEPITT" name="[searchEPITT]"
                 (ngModelChange)="FilterByEPITT()" id="epitt-label" maxlength="5" autocomplete="off"
                 aria-describedby="filter-epitt-addon">
        </div>
      </div>

      <div style="float: left; margin-left: 2%" *ngIf="UserIsAdmin()">
        <button class="btn btn-success" (click)="ExportAllSignalsAsExcel()">Export all signals to excel</button>
      </div>
    </div>
  </div>

  <div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th>EPITT&#8209;Nr <!--<a (click)="SortByEpitt()"><i class="ng {{sortEpittIcon}} icon"></i></a>>--></th>
        <th>Term</th>
        <th>Source</th>
        <th>Substances & Classes</th>
        <th>Rapporteur</th>
        <th>Authorization Type</th>
        <!--<th>Committee</th>
        <th>Comment</th>
        <th>Opinion</th>-->
        <th>Signal Events</th>
        <th>Recommendations / Final Outcome</th>
      </tr>
      </thead>
      <tbody style="overflow: auto">
        <tr *ngFor="let signal of signals">
          <!-- EPITT -->
          <td>
            <a [routerLink]="['../wiki/signal/', signal.epitt]">{{signal.epitt}}</a>
            &nbsp;
            <span *ngIf="signal.comment !== ''">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-left-text" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg" tooltip="{{signal.comment}}">
                <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path fill-rule="evenodd" d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </span>
          </td>

          <!-- Comment -->
          <td class="col-2">
            {{signal.term}}
          </td>

          <!-- Signal Source -->
          <td>{{signal.source}}</td>

          <!-- Substances & Classes -->
          <td>
            <span *ngFor="let substance of signal.substances">
              {{substance['INN_EN']}}<br>
            </span>
            <span *ngFor="let tclass of signal.therapeuticClasses">
              {{tclass['Description_EN']}}<br>
            </span>
          </td>

          <!-- Rapporteur -->
          <td>
            <a [routerLink]="['../wiki/rapporteur/', signal.rapporteurID]">{{signal.rapporteur}}</a>
            <span *ngFor="let rapp of signal.additionalRapporteurs">
              , <a [routerLink]="['../wiki/rapporteur/', rapp['RapporteurID']]">{{rapp['RapporteurCode']}}</a>
            </span>
          </td>

          <!-- Authorization Type -->
          <td>{{AuthorizationTypeToString(signal)}}</td>

          <!-- Signal Events -->
          <td colspan="2" class="col-3">
            <table>
              <tr *ngFor="let signalevent of signal.signalEvents">
                <td *ngIf="signalevent['IsFollowUp'] === '1'; else isinitial">
                  Follow-Up
                  <br>
                  ({{signalevent['FDate']}})
                </td>
                <ng-template #isinitial>
                  <td>
                    Initial
                    <br>
                    ({{signalevent['FDate']}})
                  </td>
                </ng-template>
                <td>
                  <span *ngIf="signalevent['Recommendations'].length === 0 && signalevent['OtherRecommendation'] === ''"
                        style="color: red">
                    Ongoing
                  </span>
                  <span *ngFor="let recommendation of signalevent['Recommendations']">
                    <span *ngIf="signalevent['IsFinal'] === '1'; else isnotfinal">
                      <b>{{recommendation['Final_outcome']}}</b>
                    </span>
                    <ng-template #isnotfinal>
                      {{recommendation['PRAC_Signal_Recommendation']}}
                    </ng-template>
                    <br>
                  </span>
                  <span *ngIf="signalevent['IsFinal'] === '1'; else isnotfinal">
                    <b>{{signalevent['OtherRecommendation']}}</b>
                  </span>
                  <ng-template #isnotfinal>
                    {{signalevent['OtherRecommendation']}}
                  </ng-template>
                  <span *ngIf="signalevent['Comments'] !== ''">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-left-text" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" tooltip="{{signalevent['Comments']}}">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    Source: &copy; <b>EMA</b> (<a href="https://www.ema.europa.eu" target="_blank">www.ema.europa.eu</a>).
  </div>
</div>
