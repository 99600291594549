import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SingleSignOnService {
  validationURL = 'https://www.vfa.de/ws/applicationrest/rest/signaldatenbankauthentication?token=';
  token = '';

  constructor(private http: HttpClient, private route: ActivatedRoute) {
  }

  ValidateToken(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.token = params.get('token');
      const x = await this.TokenIsValid(this.token);
      console.log(x);
    });
  }

  async TokenIsValid(token: string): Promise<boolean> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const result = await this.http.post<any>(this.validationURL + token, {headers}).toPromise();
    console.log(result);

    return false;
  }
}
