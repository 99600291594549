import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class LogService {

  constructor(private http: HttpClient) {
  }

  async LogIn(username: string, password: string, rememberMe: boolean): Promise<boolean> {
    this.LogOut();
    const body = {
      loggedIn: '',
      un: username,
      pw: password
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const result = await this.http.post<any>('https://vfa-signals.de/scripts/pseudologin.php', body, {headers}).toPromise();

    if (result.success) {
      this.WriteSessionStorage(result.username, result.isAdmin);

      if (rememberMe) {
        this.WriteLocalStorage(result.username, result.isAdmin);
      }
    }
    console.log('test:', result);
    return result.success;
  }

  WriteSessionStorage(username: string, isAdmin: boolean): void {
    sessionStorage.clear();
    sessionStorage.setItem('UName', username);

    if (isAdmin) {
      sessionStorage.setItem('UIsAdmin', 'on');
    } else {
      sessionStorage.setItem('UIsAdmin', 'off');
    }
  }

  WriteLocalStorage(username: string, isAdmin: boolean): void {
    localStorage.clear();
    localStorage.setItem('UName', username);

    if (isAdmin) {
      localStorage.setItem('UIsAdmin', 'on');
    } else {
      localStorage.setItem('UIsAdmin', 'off');
    }
  }

  IsLoggedInLocalStorage(): boolean {
    return (localStorage.getItem('UName')  !== null && (localStorage.getItem('UIsAdmin') !== null));
  }

  IsLoggedInSessionStorage(): boolean {
    return (sessionStorage.getItem('UName') !== null && (sessionStorage.getItem('UIsAdmin') !== null));
  }

  IsLoggedIn(): any {
    if (this.IsLoggedInLocalStorage()) {
      return true;
    } else {
      return this.IsLoggedInSessionStorage();
    }
  }

  IsLoggedInAdmin(): boolean {
    if (this.IsLoggedIn()) {
      return (sessionStorage.getItem('UIsAdmin') === 'on') || (localStorage.getItem('UIsAdmin') === 'on');
    } else {
      return false;
    }
  }

  LogOut(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  GetUserName(): string {
    if (sessionStorage.getItem('UName') !== '') {
      return sessionStorage.getItem('UName');
    } else {
      return localStorage.getItem('UName');
    }
  }
}
