import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { projectGetScriptPath, AuthorizationState, projectSetScriptPath} from '../../global-constants';

@Component({
  selector: 'app-drug-product-wiki',
  templateUrl: './drug-product-wiki.component.html',
  styleUrls: ['./drug-product-wiki.component.css']
})
export class DrugProductWikiComponent {
  siteURL: Location;
  DrugProductID: string;
  DrugProductName = '';
  DrugProductMAH = '';
  DrugProductEUNumber = '';
  DrugProductEMALink = '';
  DrugProductStatus = '';
  DrugProductIndications = '';
  DrugProductAuthorisationDate = '';
  DrugProductOrphanDesignationCount = 0;
  DrugProductSignals = [];
  DrugProductActiveSubstances = [];

  constructor(private http: HttpClient, private router: Router, private location: Location) {
    this.siteURL = location;
  }

  getDrugProductIDfromURL(): string {
    return this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    this.loadDrugProductByID(this.getDrugProductIDfromURL());
  }

  loadDrugProductByID(dp_id): void {
    console.log(dp_id);
    const body = {
      GetDrugProductFullByID: '',
      DrugProductID: dp_id
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        console.log(response);
        this.DrugProductName = response.EU_Name;
        this.DrugProductID = response.DP_ID;
        this.DrugProductMAH = response.MAH;
        this.DrugProductEUNumber = response.EU_Number;
        this.DrugProductStatus = response.Status;
        this.DrugProductEMALink = response.EMA_Link;
        this.DrugProductIndications = response.Indications;
        this.DrugProductAuthorisationDate = response.Authorisation_Date;
        this.DrugProductOrphanDesignationCount = response.Associated_Orphan_Designation_Count;
        // this.loadRelatedActiveSubstances(response.INN_EN);
        // this.loadRelatedSignals(response.INN_EN);
    });
  }
}
