<div style="margin-left: 10px" class="mainContent">
  <h2>Signal {{SignalEPITT}}</h2>
  <div style="color: #4e555b; font-size: large; margin-top: -4pt">
    Unique EMA signal identifier
  </div>
  <hr>

  <table class="statisticsTable">
    <tr class="value">
      <td>
        <span class="flag-icon flag-icon-{{SignalRapporteurFlag.toLowerCase()}} flag-icon-squared"></span>
        {{SignalRapporteur}}
        <span *ngFor="let rapporteur of SignalRapporteurs">
          , {{rapporteur['RapporteurCode']}}
        </span>
      </td>
      <td class="blue">
        <span *ngIf="SignalIsCAP === '0' else otherAT">
          NAP
        </span>
        <ng-template #otherAT>
          <span *ngIf="SignalIsNAP === '0' else bothTypes">
            CAP
          </span>
          <ng-template #bothTypes>
            CAP & NAP
          </ng-template>
        </ng-template>
      </td>
      <td>
        <span *ngIf="SignalIsFinal === true else ongoingSignalIcon">
          <div class="green">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check" fill="green" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
            </svg>
          </div>
        </span>
        <ng-template #ongoingSignalIcon>
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-counterclockwise" fill="red" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
          </svg>
        </ng-template>
      </td>
      <td class="orange">{{SignalSignalEvents.length}}</td>
      <td class="purple">{{SignalSubstances.length}}</td>
    </tr>
    <tr class="label">
      <td>RAPPORTEUR(S)</td>
      <td>AUTHORIZATION TYPE</td>
      <td>
        <span *ngIf="SignalIsFinal === true else ongoingSignalLabel">
          Is Final
        </span>
        <ng-template #ongoingSignalLabel>
          Ongoing
        </ng-template>
      </td>
      <td>#Signal Events</td>
      <td>Affected Substance(s)</td>
    </tr>
  </table>
  <hr>

  <form style="font-size: large">
    <div class="form-group row">
      <label for="SignalTermField" class="col-sm-2 col-form-label">Term:</label>
      <div class="col-sm-10">
        <input type="text" id="SignalTermField" readonly class="form-control-plaintext" value="{{SignalTerm}}">
      </div>
    </div>
    <div class="form-group row">
      <label for="SignalTermField" class="col-sm-2 col-form-label">Rapporteur:</label>
      <div class="col-sm-10 form-control-plaintext" >
        <a [routerLink]="['../../rapporteur/' + SignalRapporteurID]">{{SignalRapporteurFull}}</a>
        <span *ngIf="SignalRapporteurs.length > 0">
            (lead)
        </span>
        <span *ngFor="let rapp of SignalRapporteurs">
            , <a [routerLink]="['../wiki/rapporteur/', rapp['RapporteurID']]">{{rapp['RapporteurFull']}}</a>
        </span>
      </div>
    </div>
    <div class="form-group row" *ngIf="SignalIsFinal">
      <label class="col-sm-2 col-form-label">Final Outcome:</label>
      <div class="col-sm-10">
        <span *ngFor="let fo of this.SignalFinalOutcome">
          {{fo}} <br>
        </span>
      </div>
    </div>
    <div class="form-group row" *ngIf="SignalComment !== ''">
      <label for="SignalCommentField" class="col-sm-2 col-form-label">Comment:</label>
      <div class="col-sm-10">
        <input type="text" id="SignalCommentField" readonly class="form-control-plaintext" value="{{SignalComment}}">
      </div>
    </div>
  </form>

  <hr>
  <h4>Affected Drugs</h4>
  <div class="row">
    <div class="col ">
      <h5>Active Substances</h5>
      <div *ngIf="SignalSubstances.length === 0">
        <i>No Active Substances affected by this signal</i>
      </div>
      <div class="list-group">
        <a *ngFor="let substance of SignalSubstances" class="list-group-item list-group-item-action"
           [routerLink]="['../../substance/', substance['AI_ID']]">
          <img src="https://vfa-signals.de/media/molecule.png" alt="Molecule placeholder icon" class="img-fluid" width="25px"
               style="margin-right: 5px">
          {{substance['INN_EN']}}
          <small *ngIf="substance['TherapeuticClasses'].length > 0">
            <br>
            {{substance['TherapeuticClasses'][0]['Description_EN']}}
          </small>
        </a>
      </div>
    </div>
    <div class="col">
      <h5>Therapeutic Classes</h5>
      <div *ngIf="SignalTherapeuticClasses.length === 0">
        <i>No Therapeutic Classes affected by this signal</i>
      </div>
      <div class="list-group">
        <a *ngFor="let Tclass of SignalTherapeuticClasses" class="list-group-item list-group-item-action"
           [routerLink]="['../../therapeutic-class/', Tclass['TC_ID']]">
          {{Tclass['Description_EN']}}
        </a>
      </div>
    </div>
  </div>
  <br>

  <hr>
  <h4>Signal Events</h4>
  <table class="table table-hover" style="width: 85%; border-radius: 13px">
    <thead class="">
      <tr>
        <th scope="col">Event</th>
        <th scope="col">Date</th>
        <th scope="col">Recommendation(s)</th>
        <th scope="col">Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let signalEvent of SignalSignalEvents">
        <td *ngIf="IsLoggedIn() else seNotLoggedIn">
          <a *ngIf="signalEvent['IsInitial'] == '1' else isfu"
             [routerLink]="['../../../edit-db/event:' + signalEvent['SE_ID']]">
            Initial
          </a>
          <ng-template #isfu>
            <a [routerLink]="['../../../edit-db/event:' + signalEvent['SE_ID']]">Follow-Up</a>
          </ng-template>
          <span *ngIf="signalEvent['IsFinal'] == '1'" style="font-weight: bold"><br>(final)</span>
        </td>
        <ng-template #seNotLoggedIn>
          <td>
            <span *ngIf="signalEvent['IsInitial'] == '1' else isfu">Initial</span>
            <ng-template #isfu>Follow-Up</ng-template>
            <span *ngIf="signalEvent['IsFinal'] == '1'" style="font-weight: bold"><br>(final)</span>
          </td>
        </ng-template>
        <td>{{signalEvent['Date']}}</td>
        <td>
          <div class="ui squared list">
            <div class="item" *ngIf="signalEvent['OtherRecommendation'] !== ''">
              {{signalEvent['OtherRecommendation']}}
            </div>
            <div class="item" *ngFor="let recommendation of signalEvent['Recommendations']">
              {{recommendation['PRAC_Signal_Recommendation']}}
            </div>
          </div>
        </td>
        <td>{{signalEvent['Comments']}}</td>
      </tr>
    </tbody>
  </table>

  <hr>
  <h4>Related Documents</h4>
  <i>No documents are related to this signal.</i>

  <hr>
  <div *ngIf="IsLoggedIn()">
    <h4>Actions</h4>
    <div class="btn-group" role="group" aria-label="Button Group" style="color: white">
      <a type="button" class="btn btn-primary" [routerLink]="['../../../edit-db/signal:' + SignalEPITT]">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Modify/Delete Signal
      </a>
      <button type="button" class="btn btn-primary" [routerLink]="['../../../edit-db/new-event:' + SignalEPITT]">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-square" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Add Signal Event
      </button>
      <!--<button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
        </svg>
        Delete Signal
      </button>
      <button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-envelope-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
        </svg>
        Send Signal Report
      </button>-->
    </div>
  </div>
</div>
