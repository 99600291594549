import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { projectGetScriptPath } from '../../../global-constants';

@Component({
  selector: 'app-rapporteurs-by-year',
  templateUrl: './rapporteurs-by-year.component.html',
  styleUrls: ['./rapporteurs-by-year.component.css']
})
export class RapporteursByYearComponent implements OnInit {
  selectedYear = 'Accumulated';
  id: number;
  possibleYears = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14
      }
    },
   /*  scales : {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 5
        }
      }]
    }*/
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgb(4,130,195)',
      borderColor: 'rgba(0,0,24,0)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgb(4,130,195)',
      borderColor: 'rgba(225,10,24,0.2)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    }
  ];

  public barChartData: ChartDataSets[] = [
    { data: [28, 48, 40, 19, 86, 27, 90, 74, 32, 56, 11], label: '#Signals' }
  ];

  constructor(private http: HttpClient) { }

  getData(year): void {
    let body = {};
    if (year === 'Accumulated') {
      body = {
        GetSignalCountsByRapporteurs: ''
      };
    } else {
      body = {
        GetRapporteursByYear: '',
        Year: year
      };
    }

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.barChartLabels = [];
      this.barChartData[0].data = [];
      const data = [];
      for (const item of response) {
        this.barChartLabels.push(item.RapporteurCode);
        data.push(+item['COUNT(*)']);
      }
      this.barChartData[0].data = data;
    });
  }

  getYears(): void {
    const body = {
      GetMinMaxSignalDates: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.possibleYears = ['Accumulated'];
      const minYear = new Date(response['MIN(SignalEvent.Date)']);
      const maxYear = new Date(response['MAX(SignalEvent.Date)']);
      for (let i = minYear.getFullYear(); i <= maxYear.getFullYear(); i++) {
        this.possibleYears.push(i);
      }
    });
  }

  changeYear(): void {
    this.getData(this.selectedYear);
  }

  ngOnInit(): void {
    this.getYears();
    this.changeYear();
  }

}
