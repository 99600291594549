<div style="margin-left: 10px" class="mainContent">
  <h2>Access the PASCAL toolbox</h2>
  <div style="color: #4e555b; font-size: large; margin-top: -4pt">
    Pharmacovigilance Analysis and Search Algorithm Library
  </div>
  <hr>

  <div>
    <p>
      <button class="button btn-primary" (click)="Test()">Test</button>
    </p>
    <div>
    </div>
  </div>
</div>
