<div style="margin-left: 10px" class="mainContent">
  <h2>{{RapporteurCountry}}</h2>
  <div style="color: #4e555b; font-size: large; margin-top: -4pt">
    Rapporteur Country
  </div>
  <hr>

  <table class="statisticsTable">
    <tr class="value">
      <td class="red">{{RapporteurID}}</td>
      <td class="orange"><span class="flag-icon flag-icon-{{RapporteurFlag.toLowerCase()}} flag-icon-squared"></span></td>
      <td class="orange">{{RapporteurCode}}</td>
      <td class="purple">{{RapporteurSignals.length}}</td>
      <!--td class="blue"></td-->
    </tr>
    <tr class="label">
      <td>RAPPORTEUR ID</td>
      <td>RAPPORTEUR FLAG</td>
      <td>RAPPORTEUR CODE</td>
      <td>#Signals</td>
      <!--td>Rank</td-->
    </tr>
  </table>

  <hr>
  <h4>Signals</h4>

  <app-small-signal-table [(ngModel)]="RapporteurSignals"></app-small-signal-table>

  <hr>
  <h4>Related Documents</h4>
  <i>No documents are related to this rapporteur.</i>

  <!--
  <hr>
  <div *ngIf="IsAdmin()">
    <h4>Actions</h4>
    <div class="btn-group" role="group" aria-label="Button Group" style="color: white">
      <a type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Edit Rapporteur
      </a>
      <button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
        </svg>
        Delete Rapporteur
      </button>
      <button type="button" class="btn btn-primary">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-envelope-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
        </svg>
        Export Report
      </button>
    </div>
  </div>
  -->
</div>
