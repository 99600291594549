import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { projectGetScriptPath } from '../../../global-constants';

@Component({
  selector: 'app-overview-on-signals',
  templateUrl: './overview-on-signals.component.html',
  styleUrls: ['./overview-on-signals.component.css']
})
export class OverviewOnSignalsComponent implements OnInit {
  isStacked: string;
  isSeparate: string;

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14,
        fontFamily: 'Arial'
      }
    },
    scales: {
      yAxes: [{
        stacked: true
      }]
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(4, 130, 195, 0.8)'
    },
    {
      backgroundColor: 'rgba(72, 190, 251, 0.8)'
    },
    {
      backgroundColor: 'rgba(33, 65, 108, 0.8)'
    }
  ];

  public barChartData: ChartDataSets[] = [
    {data: [], label: 'New signals from EU spontaneous reporting system', stack: 'a'},
    {data: [], label: 'New signals from other sources', stack: 'b'},
    {data: [], label: 'Signals follow-up and prioritisation', stack: 'c'}
  ];

  constructor(private http: HttpClient) { }

  getSignalOverview(): void {
    const body = {
      GetSignalOverview: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.barChartLabels = [];
      this.barChartData[0].data = [];
      this.barChartData[1].data = [];
      this.barChartData[2].data = [];

      for (const item of response) {
        if (!this.barChartLabels.includes(item[3])) {
          this.barChartLabels.push(item[3]);
        }
      }

      const table = [new Array(this.barChartLabels.length), new Array(this.barChartLabels.length), new Array(this.barChartLabels.length),
        new Array(this.barChartLabels.length)];
      table[0] = this.barChartLabels;
      table[1].fill(0, 0, this.barChartLabels.length);
      table[2].fill(0, 0, this.barChartLabels.length);
      table[3].fill(0, 0, this.barChartLabels.length);
      for (const item of response) {
        const year = table[0].findIndex(element => element === item[3]);
        if (item[2] === '1') {
          if (item[1] === '0') {
            table[1][year] = +item[0];
          } else {
            table[2][year] = +item[0];
          }
        } else {
          table[3][year] = table[3][year] + +item[0];
        }
      }

      this.barChartData[0].data = table[1];
      this.barChartData[1].data = table[2];
      this.barChartData[2].data = table[3];
    });
  }

  ToggleStackView(): void {
    const val = this.barChartData[0].data;
    this.barChartData[0].data = [];
    if (this.isStacked === '') {
      // this.barChartOptions.scales.yAxes.stacked = true;
      this.isStacked = 'active';
      this.isSeparate = '';
      this.barChartData[0].stack = 'a';
      this.barChartData[1].label = 'a';
      this.barChartData[2].stack = 'a';
    } else {
      this.isStacked = '';
      this.isSeparate = 'active';
      this.barChartData[0].stack = 'a';
      this.barChartData[1].stack = 'b';
      this.barChartData[2].stack = 'c';
    }
    this.barChartData[0].data = val;
    // this.getSignalOverview();
  }

  ngOnInit(): void {
    this.isStacked = '';
    this.isSeparate = 'active';
    this.getSignalOverview();
  }

}
