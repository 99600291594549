<div class="contentTabBorder">
  <h2>Final Outcomes</h2>
  <div class="row">
    <div style="display: block" class="col-6">
      <div class="row" style="margin-bottom: 1%">
        <!-- YEAR SELECT -->
        <div class="btn-group col-md-3" dropdown>
          <button id="SignalOverviewRowSelectButton" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                  aria-controls="dropdown-basic" [(ngModel)]="selectedYear" (ngModelChange)="changeYear()" name="year-select-button">
            {{selectedYear}}<span class="caret"></span>
          </button>
          <ul id="SignalOverviewRowSelectItems" *dropdownMenu class="dropdown-menu" role="menu"
              aria-labelledby="SignalOverviewRowSelectButton">
            <li *ngFor="let o of possibleYears" role="menuitem" (click)="selectedYear = o; changeYear()"><a class="dropdown-item">{{o}}</a></li>
          </ul>
        </div>

        <!-- PERCENTAGE TOGGLE BUTTON -->
        <div class="custom-control custom-checkbox col-md-6">
          <input class="custom-control-input" type="checkbox" id="percentage-toggle" [(ngModel)]="togglePercentages"
                 (ngModelChange)="getData(selectedYear)" name="percentage-toggle">
          <label class="custom-control-label" for="percentage-toggle">Show percentages instead of total numbers</label>
        </div>
      </div>
      <div class="row" style="display: block">
        <canvas baseChart
                [data]="pieChartData"
                [labels]="pieChartLabels"
                [chartType]="pieChartType"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend"
                [colors]="pieChartColors">
        </canvas>
      </div>
    </div>
    <div class="col-6">
      <table class="table" style="width: auto">
        <thead>
        <tr>
          <th>Abbreviation</th>
          <th>Explanation</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>DHCP</td>
          <td>Direct healthcare professional communication</td>
        </tr>
        <tr>
          <td>DUS</td>
          <td>Drug utilisation study</td>
        </tr>
        <tr>
          <td>PASS</td>
          <td>Post-authorization safety study</td>
        </tr>
        <tr>
          <td>PI update</td>
          <td>Product-information update</td>
        </tr>
        <tr>
          <td>PSUR</td>
          <td>Periodic safety update report</td>
        </tr>
        <tr>
          <td>RMP</td>
          <td>Risk management plan</td>
        </tr>
        <tr>
          <td>Routine PV</td>
          <td>Routine pharmacovigilance</td>
        </tr>
        <tr>
          <td>V</td>
          <td>Variation</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
