import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LogService } from '../../shared/services/login.service';
import { projectGetScriptPath, AuthorizationState } from '../../global-constants';

@Component({
  selector: 'app-signal-wiki',
  templateUrl: './signal-wiki.component.html',
  styleUrls: ['./signal-wiki.component.css']
})
export class SignalWikiComponent implements OnInit {
  SignalEPITT: string;
  SignalTerm: string;
  SignalSignalEvents: any;
  SignalSubstances: any;
  SignalTherapeuticClasses: any;
  SignalRapporteur: any; // = RapporteurCode
  SignalRapporteurID: any;
  SignalRapporteurFull: any;
  SignalRapporteurFlag = '';
  SignalRapporteurs: any;
  SignalIsCAP: any;
  SignalIsNAP: any;
  SignalComment: any;
  SignalIsFinal = false;
  SignalFinalOutcome = Array();

  LoggedIn = false;

  constructor(private http: HttpClient, private router: Router, private LogInService: LogService) { }

  getEPITT(): string {
    const id = this.router.url.split('/')[3];
    return id;
  }

  getSignal(epitt): void {
    const body = {
      GetSignalByEpitt: '',
      SignalEpitt: epitt
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.SignalTerm = response.Signal.Term;
      this.SignalSignalEvents = response.SignalEvents;
      this.SignalSubstances = response.Substances;
      this.SignalTherapeuticClasses = response.TherapeuticClasses;
      this.SignalRapporteur = response.Signal.RapporteurCode;
      this.SignalRapporteurID = response.Signal.RapporteurID;
      this.SignalRapporteurFull = response.Signal.RapporteurFull;
      this.SignalRapporteurFlag = response.Signal.RapporteurFlag;
      this.SignalIsCAP = response.Signal.IsCAP;
      this.SignalIsNAP = response.Signal.IsNAP;
      this.SignalComment = response.Signal.Comment;
      this.SignalRapporteurs = response.AdditionalRapporteurs;

      for (const item of this.SignalSignalEvents) {
        if (item.IsFinal === '1') {
          this.SignalIsFinal = true;
        }
      }

      this.getFinalOutcome();
    });
  }

  getFinalOutcome(): void {
    if (this.SignalSignalEvents.length === 0) {
      return;
    }

    for (const i of this.SignalSignalEvents) {
      if (i.IsFinal === '1') {
        for (const j of i.Recommendations) {
          this.SignalFinalOutcome.push(j.Final_outcome);
        }
      }
    }
  }

  modifySignal(): void {
    // console.log(this.router.navigate(['../../edit-db?signal_epitt=', this.SignalEPITT]), { relativeTo: this.router });
    // console.log(this.router.navigate(['../../edit-db?', { signal_epitt: this.SignalEPITT}]), { relativeTo: this.router });
    // window.location.href = ('https://vfa-signals.de/edit-db?signal_epitt=' + this.SignalEPITT);
  }

  addSignalEvent(): void {
    // this.router.navigate(['/../../', '?signal_epitt=' + this.Signal_EPITT]);
    // window.location.href = ('https://userpage.fu-berlin.de/mlappis9916/molar/?new_epitt=' + this.SignalEPITT);
  }

  ngOnInit(): void {
    this.SignalEPITT = this.getEPITT();
    this.getSignal(this.SignalEPITT);
  }

  IsLoggedIn(): boolean {
    // return this.LogInService.IsLoggedIn();
    return AuthorizationState.UserIsAdmin();
  }
}
