<div class="withborder">
  <h2>Signals by PRAC Meeting</h2>

  <div class="row" style="margin: 1% 1% 1% 0.5%;width: 95%">
    <div style="padding-right: 1%">
      <pagination [totalItems]="signalCount" [(ngModel)]="resultPage" (ngModelChange)="RefreshSearchResults()"
                  [maxSize]="6" [boundaryLinks]="true" [itemsPerPage]="resultCount"></pagination>
    </div>

    <div style="float: left">
      <!-- Choose number of rows -->
      <div class="btn-group" dropdown>
        <button id="SignalOverviewRowSelectButton" dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn"
                aria-controls="dropdown-basic">
          Result count per page: {{resultCount}} <span class="caret"></span>
        </button>
        <ul id="SignalOverviewRowSelectItems" *dropdownMenu class="dropdown-menu" role="menu"
            aria-labelledby="SignalOverviewRowSelectButton">
          <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(5)">5</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(10)">10</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(25)">25</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="setResultCount(50)">50</a></li>
        </ul>
      </div>
    </div>

    <div style="float: left; margin-left: 2%; width: 15%">
      Meeting Date:
      <div class="btn-group" style="margin-left: 0.75%" dropdown>
        <button id="PracMeetingMonth" dropdownToggle type="button" class="btn btn-primary dropdown-toggle btn"
                aria-controls="dropdown-basic">
          {{meetingMonthString}} <span class="caret"></span>
        </button>
        <ul id="PracMeetingMonthList" *dropdownMenu class="dropdown-menu" role="menu"
            aria-labelledby="PracMeetingMonth">
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(1)">January</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(2)">February</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(3)">March</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(4)">April</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(5)">May</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(6)">June</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(7)">July</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(8)">August</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(9)">September</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(10)">October</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(11)">November</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="changeMeetingMonth(12)">December</a></li>
        </ul>
      </div>
    </div>

    <div class="btn-group" dropdown style="margin-left: 1%; height: min-content">
      <button id="PracMeetingYear" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-basic" [(ngModel)]="meetingYear" name="year-select-btn">
        {{meetingYear}}<span class="caret"></span>
      </button>
      <ul id="PracMeetingYearItems" *dropdownMenu class="dropdown-menu" role="menu"
          aria-labelledby="PracMeetingYear">
        <li *ngFor="let o of possibleYears" role="menuitem" (click)="changeMeetingYear(o)"><a class="dropdown-item">{{o}}</a></li>
      </ul>
    </div>

    <div style="float: left; width: 28%; margin-left: 1%; visibility: hidden">
      <table>
        <tbody>
          <tr>
            <td style="padding-right: 10px"><label for="PracMeetingDate">PRAC Meeting Date:</label></td>
            <td>
              <input class="form-control" placeholder="MM.YYYY" name="settings-last-modified-date" [(ngModel)]="PracMeetingDate"
                     value="{{ PracMeetingFormattedDate | date:'MM/yyyy' }}" [(bsValue)]="PracMeetingFormattedDate"
                     id="PracMeetingDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM.YYYY' }" autocomplete="off"
                     (bsValueChange)="readMeetingDateFromDateTimePicker()">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div style="width: 98%">
    <table class="table table-hover" *ngIf="signals.length > 0; else noMeeting">
      <thead>
      <tr>
        <th>EPITT&#8209;Nr <!--<a (click)="SortByEpitt()"><i class="ng {{sortEpittIcon}} icon"></i></a>>--></th>
        <th>Term</th>
        <th>Source</th>
        <th>Substances & Classes</th>
        <th>Rapporteur</th>
        <th>Authorization Type</th>
        <!--<th>Committee</th>
        <th>Comment</th>
        <th>Opinion</th>-->
        <th>Signal Events</th>
        <th>Recommendations / Final Outcome</th>
      </tr>
      </thead>
      <tbody style="overflow: auto">
      <tr *ngFor="let signal of signals">
        <!-- EPITT -->
        <td>
          <a [routerLink]="['../wiki/signal/', signal.epitt]">{{signal.epitt}}</a>
          &nbsp;
          <span *ngIf="signal.comment !== ''">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-left-text" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg" tooltip="{{signal.comment}}">
                <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path fill-rule="evenodd" d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </span>
        </td>

        <!-- Comment -->
        <td class="col-2">
          {{signal.term}}
        </td>

        <!-- Signal Source -->
        <td>{{signal.source}}</td>

        <!-- Substances & Classes -->
        <td>
            <span *ngFor="let substance of signal.substances">
              {{substance['INN_EN']}}<br>
            </span>
          <span *ngFor="let tclass of signal.therapeuticClasses">
              {{tclass['Description_EN']}}<br>
            </span>
        </td>

        <!-- Rapporteur -->
        <td>
          <a [routerLink]="['../wiki/rapporteur/', signal.rapporteurID]">{{signal.rapporteur}}</a>
          <span *ngFor="let rapp of signal.additionalRapporteurs">
            , <a [routerLink]="['../wiki/rapporteur/', rapp['RapporteurID']]">{{rapp['RapporteurCode']}}</a>
          </span>
        </td>

        <!-- Authorization Type -->
        <td>{{AuthorizationTypeToString(signal)}}</td>

        <!-- Signal Events -->
        <td colspan="2" class="col-3">
          <table>
            <tr *ngFor="let signalevent of signal.signalEvents">
              <td *ngIf="signalevent['IsFollowUp'] === '1'; else isinitial">
                Follow-Up
                <br>
                ({{signalevent['FDate']}})
              </td>
              <ng-template #isinitial>
                <td>
                  Initial
                  <br>
                  ({{signalevent['FDate']}})
                </td>
              </ng-template>
              <td>
                  <span *ngIf="signalevent['Recommendations'].length === 0 && signalevent['OtherRecommendation'] === ''"
                        style="color: red">
                    Ongoing
                  </span>
                <span *ngFor="let recommendation of signalevent['Recommendations']">
                    <span *ngIf="signalevent['IsFinal'] === '1'; else isnotfinal">
                      <b>{{recommendation['Final_outcome']}}</b>
                    </span>
                    <ng-template #isnotfinal>
                      {{recommendation['PRAC_Signal_Recommendation']}}
                    </ng-template>
                    <br>
                  </span>
                <span *ngIf="signalevent['IsFinal'] === '1'; else isnotfinal">
                    <b>{{signalevent['OtherRecommendation']}}</b>
                  </span>
                <ng-template #isnotfinal>
                  {{signalevent['OtherRecommendation']}}
                </ng-template>
                <span *ngIf="signalevent['Comments'] !== ''">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-left-text" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" tooltip="{{signalevent['Comments']}}">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                  </span>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
    <ng-template #noMeeting>
      <div class="alert alert-info" role="alert">
        <svg style="margin-top: 0.2em" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
        &nbsp;
        There has been no meeting of the PRAC at {{meetingMonthString}} {{meetingYear}}.
      </div>
      <br>
    </ng-template>
    Source: &copy; <b>EMA</b> (<a href="https://www.ema.europa.eu" target="_blank">www.ema.europa.eu</a>).
  </div>
</div>
