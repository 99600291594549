import { Component, OnInit } from '@angular/core';
import { AuthorizationState } from '../../global-constants';

@Component({
  selector: 'app-statistics-page',
  templateUrl: './statistics-page.component.html',
  styleUrls: ['./statistics-page.component.css']
})
export class StatisticsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  IsLoggedInAsAdmin(): boolean {
    return AuthorizationState.UserIsAdmin();
  }

}
