import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SignalClass, AuthorizationTypeToString } from '../shared/classes/signal-class';
import { projectGetScriptPath, AuthorizationState } from '../global-constants';
import { ExportSignalsAsExcel } from '../shared/services/excel-export';

@Component({
  selector: 'app-signals-overview',
  templateUrl: './signals-overview.component.html',
  styleUrls: ['./signals-overview.component.css']
})
export class SignalsOverviewComponent implements OnInit {

  signals: SignalClass[];
  signalCount;
  PageCount: number;
  resultCount = 5;
  resultPage = 1;
  EpittSort = 0;
  sortEpittIcon = 'sort';
  selectedEPITT = '';

  constructor(private http: HttpClient) { }

  GetSignals(): any {
    const body = {
      GetAllSignals: '',
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });

    return this.http.post<SignalClass[]>(projectGetScriptPath, body,
      {headers});
  }

  GetSignalsByRange(): any {
    const body = {
      GetSignalsByRange: '',
      StartCount: (this.resultPage - 1) * this.resultCount,
      ResultCount: this.resultCount,
      SortByEpitt: this.EpittSort,
      FilterEPITT: this.selectedEPITT
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });
    return this.http.post<SignalClass[]>(projectGetScriptPath, body,
      {headers});
  }

  GetSignalCount(): any {
    const body = {
      GetSignalsCount: '',
      FilterEPITT: this.selectedEPITT
    };

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });

    return this.http.post(projectGetScriptPath, body,
      {headers});
  }

  RefreshSearchResults(): void {
    this.GetSignalsByRange().subscribe((response) => {
      this.signals = response;
    });
    this.GetSignalCount().subscribe((response) => this.signalCount = response);
    this.PageCount = this.GetPageCount();
    this.resultPage = 1;
  }

  setResultCount(value): void {
    this.resultCount = value;
    this.RefreshSearchResults();
  }

  FilterByEPITT(): void {
    this.RefreshSearchResults();
  }

  GetPageCount(): number {
    return Math.ceil(this.signalCount / this.resultCount);
  }

  ngOnInit(all = true): void {
    if (!all) {
      this.GetSignals().subscribe((response) => this.signals = response);
    } else {
      this.GetSignalsByRange().subscribe((response) => this.signals = response);
    }

    this.GetSignalCount().subscribe((response) => {
      this.signalCount = response;
    });
  }

  ExportAllSignalsAsExcel(): void {
    let signalList;
    this.GetSignals().subscribe((response) => {
      signalList = response;
      console.log(signalList);
      ExportSignalsAsExcel(signalList, 'PRAC-Signals', this.http);
    });
  }

  AuthorizationTypeToString(signal): string {
    return AuthorizationTypeToString(signal);
  }

  UserIsAdmin(): boolean {
    return AuthorizationState.UserIsAdmin();
  }
}
