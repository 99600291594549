<div style="width: 40%" class="contentTabBorder">
  <form>
    <div class="form-group" (submit)="ValidateForm()">
      <label for="DrugProductName">Drug Product Name:</label>
      <input type="text" class="form-control" name="drug-product-name" id="DrugProductName" required="required"
             placeholder="Product Name" [(ngModel)]="DrugProductName" autocomplete="off">
    </div>
    <alert type="danger" class="{{validDrugProductName}}">
      <strong>Required!</strong> You must provide a valid Drug Product Name!
    </alert>
    <div class="form-group">
      <label for="MAH">Marketing Authorisation Holder:</label>
      <input type="text" class="form-control" name="drug-product-mah" id="MAH"
             placeholder="MAH Name" [(ngModel)]="DrugProductMAH" autocomplete="off" required="required">
    </div>
    <alert type="danger" class="{{validMAH}}">
      <strong>Required!</strong> You must provide a valid MAH!
    </alert>
    <div class="form-group">
        <label for="DrugProductSelectSubstance">Substances (separated by ','):</label>
        <input id="DrugProductSelectSubstance" class="form-control" [typeahead]="AllActiveSubstances"
               [(ngModel)]="DrugProductActiveSubstances" name="DrugProductSelectSubstance" autocomplete="off"
               [typeaheadMultipleSearch]="true" typeaheadMultipleSearchDelimiters=",|;" placeholder="Substances"
               required="required">
    </div>
    <alert type="danger" class="{{validActiveSubstance}}">
      <strong>Required!</strong> You must enter at least one active substance!
    </alert>
    <div>
      <button type="button" class="btn btn-outline-secondary form-button" (click)="ClearDrugProductForm()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary form-button" (click)="ValidateForm()">
        {{DrugProductButtonCaption}}
      </button>
      <button type="button" class="btn btn-outline-danger form-button" (click)="DeleteDrugProduct()" [disabled]="!ModifyMode">
        Delete Drug Product
      </button>
    </div>
  </form>
</div>
