<div class="mainContent">
  <!--<div id="title_image"></div>-->
  <blockquote class="blockquote">
    <div class="jumbotron">
      <h2>Welcome to the vfa database of EMA safety signals</h2>
      <p class="lead">
        Browse >730 signals on active substances or therapeutic classes assessed by the Pharmacovigilance Risk Assessment
        Committee (PRAC). The database contains all signals assessed by PRAC since September 2012.
      </p>
      <p class="lead">
        For more information please refer to <a [routerLink]="['./about']">About the project</a>.
      </p>
      <a class="btn btn-primary btn-lg" [routerLink]="['./overview']" role="button">See the list of signals</a>
      <hr class="my-4">
      <p>We provide you with:</p>
      <ul>
        <li>Detailed information on signals, substances, drug products and therapeutic classes</li>
        <li>Statistics on recommendations and rapporteur countries.</li>
      </ul>
      <p>Future analytical tools are in planning.</p>
    </div>
  </blockquote>
</div>

<!--
<div style="width: 50%; margin-left: 25%; margin-right: 25%; height: 50%; align-content: center">
  <carousel>
    <slide>
      <img src="https://vfa-signals.de/media/signals.PNG" alt="first slide">
    </slide>
    <slide>
      <img src="https://vfa-signals.de/media/Signal-DB.png" alt="second slide" width="50%">
    </slide>
    <slide>
      <img src="https://vfa-signals.de/media/logo-vfa.jpg" alt="third slide" style="display: block; width: 50%;">
    </slide>
  </carousel>
</div>
-->
