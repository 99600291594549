<div class="contentTabBorder">
  <h2>vfa Signal Database Users</h2>
  <div class="row" style="margin-left: 0.5%">
    <div class="col-8" style="display:block;">
      <div class="row" style="display: block">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [plugins]="lineChartPlugins"
                [legend]="lineChartLegend"
                [colors]="lineChartColors"
                [chartType]="lineChartType">
        </canvas>
      </div>
    </div>
  </div>
</div>
