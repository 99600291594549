import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {AuthorizationState, projectGetScriptPath, projectSetScriptPath} from '../../global-constants';

@Component({
  selector: 'app-edit-db-main',
  templateUrl: './edit-db-main.component.html',
  styleUrls: ['./edit-db-main.component.css']
})
export class EditDbMainComponent implements OnInit, AfterViewInit {
  rapporteurs: any;
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  // Signal vars
  SignalEPITT = '';
  SignalSubstances = '';
  SignalTherapeuticClasses = '';
  SignalSource = 0;
  SignalTerm = '';
  SignalRapporteur = '';
  SignalRapporteurTest = 0;
  scommittee = 0;
  SignalIsCAP = false;
  SignalIsNAP = false;
  SignalComment = '';
  ssat = 0;
  SignalOpinion = '';
  SignalOldEPITT = '';

  // Signal validation vars
  validEPITT = 'd-none';
  validTerm = 'd-none';
  validSubstanceClass = 'd-none';
  validRapporteur = 'd-none';
  validRapporteurExisting = 'd-none';
  validAuthorizationtype = 'd-none';
  validRSignal = 'd-none';
  validRDelete = 'd-none';

  buttonCaption = 'Create Signal';

  formMode = 'AddSignal';
  collapse: boolean;

  // Signal event vars
  ActiveSignalEventTab = false;
  AddRapporteurCode = '';
  AddRapporteurFullName = '';

  SignalEventID = '';
  AddSignalEventElseChange = true;
  SignalEventEPITT = '';
  SignalEventType = 0;
  SignalEventDate = '';
  SignalEventDateFormat = Date.now();
  SignalEventFormattedDate: Date;
  SignalEventIsFinal = false;
  SignalEventComments = '';
  SignalEventButtonCaption = 'Create SignalEvent';

  repitt = '';
  rdate: number;
  roptions;
  rchecks: boolean[] = new Array(19).fill(false);
  SignalEventOtherRecommendationChecked = false;
  SignalEventOtherRecommendationText = '';

  validSignalEventEPITT = 'd-none';
  validSignalEventDate = 'd-none';
  validSignalEventIsInitial = 'd-none';

  validRecommendationDate = 'd-none';
  validRecommendationSignalEvent = 'd-none';
  validRecommendationDeleteSignalEvent = 'd-none';
  validRecommendationSignalEventDate = 'd-none';

  // Drug Product vars
  selectActiveSubstances;
  productName: string;
  mah: string;
  activeSubstances: any;
  validDrugProduct = 'd-none';

  // Database setting vars
  SettingsLastModifiedDate = '';
  SettingsLastModifiedFormattedDate: Date;
  SettingsLatestRecommendationDate = '';
  SettingsLatestRecommendationFormattedDate: Date;

  // Site vars
  loggedIn = false;
  modalRef: BsModalRef;

  constructor(private http: HttpClient, private route: ActivatedRoute, private modalService: BsModalService) { }

  ngAfterViewInit(): void {
    this.ReadUrlParameters();
    this.GetDatabaseSettings();
  }

  SetFormType(): void {
    this.ReadUrlParameters();
  }

  GetSignal(epitt): void {
    const body = {
      GetSignalByEpitt: 'GetSignal',
      SignalEpitt: epitt
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      console.log(response);
      this.SignalEPITT = response.Signal.EPITT;
      this.SignalOldEPITT = this.SignalEPITT;
      this.SignalTerm = response.Signal.Term;
      this.SignalComment = response.Signal.Comment;
      this.SignalRapporteur = response.Signal.RapporteurCode;

      for (const i of response.AdditionalRapporteurs) {
        this.SignalRapporteur = this.SignalRapporteur + ', ' + i.RapporteurCode;
      }

      this.SignalOpinion = response.Signal.Opinion;
      this.scommittee = +response.Signal.Committee;

      this.SignalIsCAP = response.Signal.IsCAP !== '0';

      this.SignalIsNAP = response.Signal.IsNAP !== '0';

      this.ssat = +response.Signal.AuthorizationType;
      this.SignalSource = +response.Signal.Source ;
      this.SignalSubstances = this.ArrayListToString(response.Substances, 'INN_EN');
      this.SignalTherapeuticClasses = this.ArrayListToString(response.TherapeuticClasses, 'Description_EN'); });
  }

  GetRapporteurs(): void {
    const body = {
      GetRapporteurs: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.rapporteurs = response;
      }
    );
  }

  AddSignal(formType = 'AddSignal'): void {
    this.SignalTerm = this.SignalTerm.trim();
    this.SignalComment = this.SignalComment.trim();
    let substances = this.SignalSubstances;
    let therapeuticClasses = this.SignalTherapeuticClasses;
    const rapporteurs = this.getRapporteurs();

    const body = {
      SignalFormType: formType,
      SignalOldEpitt: this.SignalOldEPITT,
      SignalEpitt: this.SignalEPITT,
      SignalTerm: this.SignalTerm,
      SignalSource: this.SignalSource,
      SignalSAT: this.ssat,
      SignalCommittee: this.scommittee,
      SignalIsCAP: this.SignalIsCAP,
      SignalIsNAP: this.SignalIsNAP,
      SignalRID: rapporteurs[0],
      SignalRapporteurs: rapporteurs,
      SignalComment: this.SignalComment,
      SignalOpinion: this.SignalOpinion
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
      if (response === 'Code:0') {
        this.validRSignal = '';
        return;
      } else if (response === 'Code:1') {
        this.validRapporteurExisting = '';
        return;
      } else {
        substances = substances.replace(';', ',');
        therapeuticClasses = therapeuticClasses.replace(';', ',');
        const substancesArray = substances.split(', ');

        for (let i of substancesArray) {
          i = i.replace('(NAP)', '');
          i = i.replace('(CAP)', '');
          i = i.replace(' ,', ',');
          i = i.charAt(0).toUpperCase() + i.slice(1);
          i = i.trim();
          if (i !== '') {
            this.AddSignalPluSignalSubstances(this.SignalEPITT, i);
          }
        }

        const therapeuticClassesArray = therapeuticClasses.split(', ');

        for (let i of therapeuticClassesArray) {
          i = i.trim();
          if (i !== '') {
            this.AddSignalPluSignalTherapeuticClasses(this.SignalEPITT, i);
          }
        }
        if (formType === 'AddSignal') {
          this.staticTabs.tabs[1].active = true;
          this.SignalEventEPITT = this.SignalEPITT;
          this.AddSignalEventElseChange = true;
        }
        this.ClearSignalForm();
      }
    });
  }

  AddSignalPluSignalSubstances(theEpitt, theSubstance): void {
    const body = {
      SignalEPITT: theEpitt,
      NewSubstance: theSubstance
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe();
  }

  AddSignalPluSignalTherapeuticClasses($theEpitt, $theClass): void {
    const body = {
      SignalEPITT: $theEpitt,
      NewClass: $theClass
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe();
  }

  ArrayListToString(array, theIndex): string {
    let result = '';
    for (let i = 0; i < array.length; i++) {
      result = result + array[i][theIndex];
      if (i < array.length - 1) {
        result = result + ', ';
      }
    }
    return result;
  }

  ValidateSignalForm(): void {
    let valid = true;

    if (this.SignalEPITT === '') {
      this.validEPITT = '';
      valid = false;
    } else if (this.SignalTerm === '') {
      this.validEPITT = 'd-none';
      this.validTerm = '';
      valid = false;
      console.log(valid);
    } else if ((this.SignalSubstances === '') && (this.SignalTherapeuticClasses === '')) {
      this.validTerm = 'd-none';
      this.validSubstanceClass = '';
      valid = false;
    } else if (this.SignalRapporteur === '') {
      this.validSubstanceClass = 'd-none';
      this.validRapporteur = '';
      valid = false;
    } else if (!this.SignalIsNAP && !this.SignalIsCAP) {
      this.validRapporteur = 'd-none';
      this.validAuthorizationtype = '';
      valid = false;
    }



    if (valid) {
      this.SignalFormSubmit();
      this.validEPITT = 'd-none';
      this.validTerm = 'd-none';
      this.validRapporteur = 'd-none';
      this.validSubstanceClass = 'd-none';
      this.validAuthorizationtype = 'd-none';
    }
  }

  SignalFormSubmit(): void {
    if (this.formMode === 'UpdateSignal') {
      this.AddSignal('UpdateSignal');
    } else {
      this.AddSignal();
    }
  }

  DeleteSignal(): void {
    let valid = true;
    if (this.SignalEPITT === '') {
      this.validEPITT = '';
      valid = false;
    }

    if (valid) {
      alert('Signal and all related SignalEvents and Recommendations will be removed!');

      const body = {
        DeleteSignalAndRelated: '',
        SignalEpitt: this.SignalEPITT,
      };

      const headers = new HttpHeaders({'Content-Type': 'application/json'});

      this.http.post<any>(projectSetScriptPath, body,
        {headers}).subscribe((response) => {
        if (response === 'Code:0') {
          this.validRDelete = '';
        } else {
          this.ClearSignalForm();
        }
      });
    }

    this.modalRef.hide();
  }

  ClearSignalForm(): void {
    this.SignalEPITT = '';
    this.SignalOldEPITT = '';
    this.SignalSubstances = '';
    this.SignalTherapeuticClasses = '';
    this.SignalTerm = '';
    this.SignalOpinion = '';
    this.SignalRapporteur = '';
    this.SignalSource = 0;
    this.ssat = 0;
    this.scommittee = 0;
    this.SignalIsCAP = false;
    this.SignalIsNAP = false;
    this.SignalComment = '';

    this.formMode = 'AddSignal';
    this.buttonCaption = 'Create Signal';

    this.validRSignal = 'd-none';
    this.validEPITT = 'd-none';
    this.validTerm = 'd-none';
    this.validRapporteur = 'd-none';
    this.validRapporteurExisting = 'd-none';
    this.validSubstanceClass = 'd-none';
    this.validAuthorizationtype = 'd-none';
    this.validRDelete = 'd-none';
  }

  ngOnInit(): void {
    this.loggedIn = this.IsLoggedIn();
    this.GetRapporteurs();
    this.GetPossibleRecommendations();
    this.getAllActiveSubstances();
  }

  getRapporteurs(): string[] {
    const additionalRapporteurs = this.SignalRapporteur.trim().replace(';', ',').split(',');
    for (let i = 0; i < additionalRapporteurs.length; i++) {
      additionalRapporteurs[i] = additionalRapporteurs[i].trim();
    }

    return additionalRapporteurs;
  }

  // Signal event form ----------------------------------------------------------------------------------------

  AddInitialOrFollowUp(AddElseChange = true): void {
    let otherRec = '';
    if (this.SignalEventOtherRecommendationChecked) {
      otherRec = this.SignalEventOtherRecommendationText.trim();
    }
    const body = {
      SignalEventAddElseChange: AddElseChange,
      InitialFollowUpEpitt: this.SignalEventEPITT,
      InitialFollowUpIsInitial: (1 - this.SignalEventType),
      InitialFollowUpIsFU: this.SignalEventType,
      InitialFollowUpDate: this.getFormattedDateFromDatePicker(this.SignalEventFormattedDate),
      InitialFollowUpIsFinal: this.SignalEventIsFinal,
      InitialFollowUpSE_ID: this.SignalEventID,
      InitialFollowUpComment: this.SignalEventComments,
      InitialFollowUpOtherRecommendation: otherRec
    };

    console.log(body);

    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
        console.log(response);
        if (response === 'Code:0') {
          this.validSignalEventIsInitial = '';
        } else if (response === 'Code:1') {
          this.validRecommendationSignalEvent = '';
        } else if (response.toString().indexOf('Code:2') === 0) {
          this.validRecommendationSignalEventDate = '';
        } else {
          if (this.rchecks.some(this.checkIsTrue)) {
            this.repitt = this.SignalEventEPITT;
            this.rdate = this.SignalEventDateFormat;
            this.AddRecommendation(AddElseChange);
          }

          this.ClearSignalEventForm();
        }
    });
  }

  GetSignalEventBySEID(): void {
    const body = {
      GetSignalEventBySEID: '',
      SignalEventID: this.SignalEventID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        if (response !== null) {
        this.SignalEventEPITT = response.EPITT;
        this.SignalEventDate = response.FDate;
        this.SignalEventDateFormat = response.FDate;
        if (response.IsInitial === '1') {
          this.SignalEventType = 0;
        } else {
          this.SignalEventType = 1;
        }

        this.SignalEventIsFinal = response.IsFinal !== '0';
        this.SignalEventComments = response.Comments;

        for (let i = 0; i < this.rchecks.length; i++) {
          this.rchecks[i] = false;
        }

        for (const item of response.Recommendations) {
          this.rchecks[+(item.PR_Index)] = true;
        }

        this.SignalEventOtherRecommendationChecked = response.OtherRecommendation !== '';
        this.SignalEventOtherRecommendationText = response.OtherRecommendation;
      }
    });
  }

  AddRapporteurCountry(): void {
    const body = {
      RapporteurAddRapporteurCode: this.AddRapporteurCode,
      RapporteurAddRapporteurFullName: this.AddRapporteurFullName,
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body, {headers}).subscribe();

    this.AddRapporteurFullName = '';
    this.AddRapporteurCode = '';
  }

  AddRecommendation(NewSignalEvent = false): void {
    let recommendations: Array<number>;
    recommendations = [];

    for (let i = 0; i < this.roptions.length; i++) {
      if (this.rchecks[i]) {
        recommendations[i] = this.roptions[i].PRID;
      }
    }

    const body = {
      AddRecommendation: '',
      UpdateElseNewSignalEvent: !NewSignalEvent,
      RecommendationEpitt: this.repitt,
      RecommendationDate: this.getFormattedDateFromDatePicker(this.SignalEventFormattedDate),
      Recommendations: recommendations,
      SignalEventID: this.SignalEventID
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    console.log(body);

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe();

    this.repitt = '';
    this.rdate = 0;
    for (let i = 0; i < this.rchecks.length; i++) {
      this.rchecks[i] = false;
    }
  }

  ValSignalEventDateInitialOrFollowUpForm(): void {
    let valid = true;

    if (this.SignalEventEPITT === '') {
      valid = false;
      this.validSignalEventEPITT = '';
    } else if (this.SignalEventDate === '') {
      this.validSignalEventEPITT = 'd-none';
      valid = false;
      this.validSignalEventDate = '';
    } else {
      this.validSignalEventDate = 'd-none';
    }

    if (valid) {
      if (this.AddSignalEventElseChange) {
        this.AddInitialOrFollowUp();
      } else {
        this.AddInitialOrFollowUp(false);
      }
    }
  }

  GetPossibleRecommendations(): void {
    const body = {
      GetAllRecommendations: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) =>  {
      this.roptions = response;
    });
  }

  checkIsTrue(element): boolean {
    return element === true;
  }

  getAllActiveSubstances(): void {
    const body = {
      GetAllActiveSubstances: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
      this.selectActiveSubstances = response;
    });
  }

  AddDrugProduct(): void {
    const body = {
      AddDrugProduct: '',
      DrugProductName: this.productName,
      DrugProductMAH: this.mah,
      DrugProductSubstances: this.activeSubstances
    };
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
      this.selectActiveSubstances = response;
      if (response !== 'Code:0') {
        this.productName = '';
        this.mah = '';
        this.activeSubstances = [];
        this.getAllActiveSubstances();
      } else {
        this.validDrugProduct = '';
      }
    });
  }

  DeleteSignalEvent(): void {
    let valid = true;

    if (this.SignalEventEPITT === '') {
      valid = false;
      this.validSignalEventEPITT = '';
    } else if (this.SignalEventDate === '') {
      valid = false;
      this.validSignalEventEPITT = 'd-none';
      this.validSignalEventDate = '';
    }

    if (valid) {
      const byseid = (this.SignalEventID !== null && this.SignalEventID !== '');
      const body = {
        DeleteSignalEventAndRelated: '',
        // SignalEventDate: this.SignalEventDate,
        SignalEventDate: this.getFormattedDateFromDatePicker(this.SignalEventFormattedDate),
        SignalEventEpitt: this.SignalEventEPITT,
        BySignalEventID: byseid,
        SignalEventID: this.SignalEventID
      };

      const headers = new HttpHeaders({'Content-Type': 'application/json'});

      this.http.post<any>(projectSetScriptPath, body,
        {headers}).subscribe((response) => {
        if (response === 'Code:0') {
          this.validRecommendationDeleteSignalEvent = '';
        } else {
          this.ClearSignalEventForm();
        }
      });
    }
  }

  ClearSignalEventForm(): void {
    this.SignalEventID = '';
    this.SignalEventEPITT = '';
    this.SignalEventType = 0;
    this.SignalEventDate = '';
    this.SignalEventComments = '';
    this.SignalEventOtherRecommendationText = '';
    this.SignalEventOtherRecommendationChecked = false;
    this.SignalEventIsFinal = false;
    this.validSignalEventIsInitial = 'd-none';
    this.validSignalEventEPITT = 'd-none';
    this.validRecommendationSignalEvent = 'd-none';
    this.validRecommendationDeleteSignalEvent = 'd-none';
    this.validRecommendationSignalEventDate = 'd-none';
    this.SignalEventButtonCaption = 'Create SignalEvent';
    this.AddSignalEventElseChange = true;

    for (let i = 0; i < this.rchecks.length; i++) {
      this.rchecks[i] = false;
    }
  }

  ReadUrlParameters(): void {
    const ParamValue = this.route.snapshot.paramMap.get('id');
    let addEvent = false;
    let ModifyDrugProduct = false;

    if (!(ParamValue === null)) {
      const Param = ParamValue.slice(ParamValue.indexOf(':') + 1, ParamValue.length);
      let NewEPITTParam = null;
      let SignalEventIDParam = null;

      if (ParamValue.slice(0, ParamValue.indexOf(':')) === 'signal') {
        NewEPITTParam = Param;
      } else if (ParamValue.slice(0, ParamValue.indexOf(':')) === 'event') {
        SignalEventIDParam = Param;
      } else if (ParamValue.slice(0, ParamValue.indexOf(':')) === 'drug-product') {
        ModifyDrugProduct = true;
      } else {
        this.ClearSignalForm();
        this.ClearSignalEventForm();
        addEvent = true;
      }

      if (SignalEventIDParam !== null) {
        this.SignalEventID = SignalEventIDParam;
        this.SignalEventButtonCaption = 'Submit Changes';
        this.AddSignalEventElseChange = false;
        this.GetSignalEventBySEID();
        this.staticTabs.tabs[1].active = true;
      } else if (NewEPITTParam !== null) {
        this.SignalEventEPITT = NewEPITTParam;
        this.formMode = 'UpdateSignal';
        this.buttonCaption = 'Modify Signal';
        this.GetSignal(Param);
        this.staticTabs.tabs[0].active = true;
      } else if (!addEvent && !ModifyDrugProduct) {
        this.formMode = 'AddSignal';
        this.buttonCaption = 'Create Signal';
        this.staticTabs.tabs[0].active = true;
      } else if (!addEvent && ModifyDrugProduct) {
        this.staticTabs.tabs[3].active = true;
      } else {
        this.formMode = 'AddSignal';
        this.buttonCaption = 'Create Signal';
        this.SignalEventEPITT = Param;
        this.staticTabs.tabs[1].active = true;
      }
    }
  }

  openModal(modalComponent: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalComponent, { class: 'modal-sm' });
  }

  IsLoggedIn(): boolean {
    return AuthorizationState.UserIsAdmin();
  }

  getFormattedDateFromDatePicker(theDate: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return theDate.toLocaleString('de-DE', options);
  }

  // Database Settings Section
  GetDatabaseSettings(): void {
    const body = {
      GetDatabaseSettings: ''
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.SettingsLastModifiedDate = response.FLastUpdated;
        this.SettingsLatestRecommendationDate = response.FLatestRecommendationDate;
    });
  }

  ClearDatabaseSettingsForm(): void {
    this.SettingsLastModifiedDate = '';
    this.SettingsLatestRecommendationDate = '';

    this.GetDatabaseSettings();
  }

  ChangeDatabaseSettings(): void {
    const body = {
      UpdateDatabaseSettings: '',
      DateLastDatabaseUpdate: this.getFormattedDateFromDatePicker(this.SettingsLastModifiedFormattedDate),
      DateLastPracRecommendations: this.getFormattedDateFromDatePicker(this.SettingsLatestRecommendationFormattedDate)
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectSetScriptPath, body,
      {headers}).subscribe((response) => {
    });
  }
}
