<!-- Header -->
<div *ngIf="loggedIn; else notLoggedIn">
  <!-- Header Caption + vfa Logo -->
  <div style="margin: 15pt 10pt 10pt;text-align: center">
    <h1>
      <a href="https://www.vfa.de" target="_blank">
        <img src="https://vfa-signals.de/media/logo-vfa.jpg" height="50px" style="float: left" alt="vfa-Logo">
      </a>
      vfa Signal Database
      <a><img src="https://vfa-signals.de/media/white.jpg" alt="white" height="50px" style="float: right">
      </a>
    </h1>
  </div>

  <!-- Header Navbar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light" style="margin-bottom: 0">
    <a class="navbar-brand" routerLink="./">
      Home
    </a>

    <!-- NavBar Items -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
             routerLinkActive="active" dropdownToggle>Signal Overview</a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li><a class="dropdown-item" routerLink="/overview">By EPITT</a></li>
            <li><a class="dropdown-item" routerLink="/prac-meetings">By PRAC Meeting</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" routerLink="/statistics">Statistics</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/search-db" routerLinkActive="active">Search DB</a>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
             routerLinkActive="active" dropdownToggle>About</a>
          <ul class="dropdown-menu" *dropdownMenu>
            <li><a class="dropdown-item" routerLink="/about">About the Project</a></li>
            <li><a class="dropdown-item" href="https://www.vfa.de/inline" target="_blank">vfa inline</a></li>
          </ul>
        </li>
        <li *ngIf="IsAdmin" class="nav-item">
          <a class="nav-link" routerLink="/edit-db" routerLinkActive="active">Edit DB</a>
        </li>
      </ul>
    </div>

    <div>
      <button class="btn-primary btn" (click)="LogOut()">Log Out</button>
    </div>
  </nav>


  <!-- Main -->
  <div style="height: 100%; width: 100%; margin-top: 0">
    <!-- Content Pages -->
    <div style="width: 99%; float: left; margin-left: 0.5%; margin-right: 0.5%; margin-bottom: 2%">
      <div class="pusher">
        <router-outlet></router-outlet>
      </div>
      <!--<app-start-page></app-start-page>-->
    </div>

    <div style="padding: 4pt; background-color: #F8F9FA; width: 100%; float: left; margin-top: 2%;"
         class="fixed-bottom">
         <!--class="position-relative bottom-0">-->
      &copy; vfa 2020 - 2023 &nbsp;&nbsp;&nbsp;<a href="https://www.vfa.de/de/impressum.html" target="_blank">Imprint</a>
      &nbsp; &nbsp;
      Last modified: {{LastUpdatedDate}} (As of: Signal recommendations dated {{LastPracRecommendationDate}})
      &nbsp;&nbsp; Data: &copy; EMA 2012 - 2023
    </div>
  </div>
</div>

<ng-template #notLoggedIn>
  <img src="https://vfa-signals.de/media/logo-vfa.jpg" height="50px" style="position: absolute;top: 20px; left: 20px" alt="vfa-Logo">
  <div *ngIf="IsLoading; else loginForm">
    <div>
      <div class="center-box">
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <ng-template #loginForm>
    <div class="login-form">
      <h1>Login</h1>
      <a href="{{this.LoginRedirectAddress}}" type="button">Login via vfa Inline</a>
      <hr>
      <div *ngIf="invalidLogIn" id="invalid_login_hint">
        <alert type="danger">
          Incorrect username or password. Please try again.
        </alert>
      </div>
      <form (ngSubmit)="checkLogInFormFilled()">
        <h1>Login via local account</h1>
        <input type="text" placeholder="Company e-mail address" [required]="true" [(ngModel)]="userMail" id="loginMail"
               name="user_mail">
        <!--<input type="password" placeholder="Password" required="required" [(ngModel)]="userPassword">-->
        <div class="password-container">
          <input type="password" placeholder="Password" [required]="true" [type]="showPassword ? 'text' : 'password'"
                 [(ngModel)]="userPassword" id="loginPW" name="user_password">
          <button (click)="togglePassword()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
            </svg>
          </button>
        </div>
        <input type="submit" value="Log in">
        <a href="/register">No account yet? Register here</a>
      </form>
    </div>
  </ng-template>
</ng-template>
