import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { projectGetScriptPath } from '../../global-constants';

@Component({
  selector: 'app-search-db',
  templateUrl: './search-db.component.html',
  styleUrls: ['./search-db.component.css']
})

export class SearchDbComponent implements OnInit {
  searchTerm = '';
  results = [];
  last: any;

  constructor(private http: HttpClient) { }

  getSearchResults(term: string): void{
    this.results = null;
    const body = {
      GlobalSearch: '',
      SearchTerm: term
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        this.results = response;
      }
    );
  }

  findTermInString(term, text: string): string {
    const pos = text.toLowerCase().indexOf(term.toLowerCase());
    let result = '';
    if (pos !== -1) {
      result = text.substring(0, pos) + '<mark>' + text.substring(pos, pos + term.length) + '</mark>' +
        text.substring(pos + term.length, text.length);
    } else {
      result = text;
    }

    return result;
  }

  SubmitSearch(): void {
    this.getSearchResults(this.searchTerm);
  }

  ngOnInit(): void {
  }

}
