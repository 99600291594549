import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ExportSignalsAsExcel } from '../shared/services/excel-export';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css']
})
export class StartPageComponent implements OnInit {

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  test(): void {
    this.route.queryParamMap.subscribe(params => {

      /*const headers = new HttpHeaders({'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'});
      this.http.post<any>('https://vfa-signals.de/scripts/validation.php', {token: params.get('token')}, {headers}).subscribe(
        (response) => {
          console.log(response);
        }
      );*/
      const path = 'https://vfa-signals.de/scripts/validation.php';
      // const path = 'https://www.vfa.de/ws/applicationrest/rest/signaldatenbankauthentication?token=' + params.get('token');
      const body = {
        token: params.get('token')
      };

      const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        /* 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, Accept, ' +
          'X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'*/
      });

      this.http.post<any>(path, body,
        {headers}).subscribe(
        (response) => {
          console.log(response);
        }
      );
    });
  }

  SignalsExport(): void {
    ExportSignalsAsExcel('', 'test', this.http);
  }

}
