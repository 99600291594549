import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { SignalsOverviewComponent } from './signals-overview/signals-overview.component';
import { EditDbMainComponent } from './edit-db/edit-db-main/edit-db-main.component';
import { SignalWikiComponent } from './wikis/signal-wiki/signal-wiki.component';
import { SubstanceWikiComponent } from './wikis/substance-wiki/substance-wiki.component';
import { TherapeuticclassWikiComponent } from './wikis/therapeuticclass-wiki/therapeuticclass-wiki.component';
import { StatisticsPageComponent } from './statistics/statistics-page/statistics-page.component';
import { SearchDbComponent } from './search/search-db/search-db.component';
import { AboutComponent } from './about/about.component';
import { SignalsByMeetingsComponent } from './signals/signals-by-meetings/signals-by-meetings.component';
import { PascalMainComponent } from './pascal/pascal-main/pascal-main.component';
import { RapporteurWikiComponent } from './wikis/rapporteur-wiki/rapporteur-wiki.component';
import { LoginFormComponent} from './shared/components/login-form/login-form.component';
import { DrugProductWikiComponent } from "./wikis/drug-product-wiki/drug-product-wiki.component";

const routes: Routes = [
  {
    path: '', component: StartPageComponent
  },
  {
    path: 'overview', component: SignalsOverviewComponent
  },
  {
    path: 'prac-meetings', component: SignalsByMeetingsComponent
  },
  {
    path: 'edit-db', component: EditDbMainComponent
  },
  {
    path: 'edit-db/:id', component: EditDbMainComponent
  },
  {
    path: 'wiki/signal/:id', component: SignalWikiComponent
  },
  {
    path: 'wiki/substance/:id', component: SubstanceWikiComponent
  },
  {
    path: 'wiki/rapporteur/:id', component: RapporteurWikiComponent
  },
  {
    path: 'wiki/therapeutic-class/:id', component: TherapeuticclassWikiComponent
  },
  {
    path: 'wiki/drug-product/:id', component: DrugProductWikiComponent
  },
  {
    path: 'statistics', component: StatisticsPageComponent
  },
  {
    path: 'search-db', component: SearchDbComponent
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'pascal', component: PascalMainComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
