import { Component, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { projectGetScriptPath } from '../../../global-constants';

@Component({
  selector: 'app-users-chart',
  templateUrl: './users-chart.component.html',
  styleUrls: ['./users-chart.component.css']
})
export class UsersChartComponent implements OnInit {

  constructor(private http: HttpClient) { }

  public lineChartLabels: Label[] = ['02-21', '03-21', '2008', '2009'];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public lineChartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgba(4,130,195, 0.2)',
      borderColor: 'rgba(4,130,195,1)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: 'rgba(33,65,105, 0.2)',
      borderColor: 'rgba(33,65,105,1)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    }
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14
      }
    },
    /*  scales : {
       yAxes: [{
         ticks: {
           beginAtZero: true,
           stepSize: 5
         }
       }]
     }*/
  };

  public lineChartData: ChartDataSets[] = [
    { data: [28, 48, 40, 19, 86, 27, 90, 74, 32, 56, 11], label: 'Admins' },
    { data: [28, 48, 40, 19, 86, 27, 90, 74, 32, 56, 11], label: 'All Users' }
  ];

  getUsers(): void {
    const body = {
      GetUsersPerMonth: '',
    };

    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    this.http.post<any>(projectGetScriptPath, body,
      {headers}).subscribe((response) => {
        console.log(response);

        this.lineChartLabels = [];
        this.lineChartData[0].data = [];
        this.lineChartData[1].data = [];

        for (const item of response) {
          this.lineChartLabels.push(item.LoginMonth);
          const adminCount = +item.AdminCount;
          this.lineChartData[0].data.push(adminCount);
          // this.lineChartData[1].data.push((+item.UserCount - adminCount));
          this.lineChartData[1].data.push(+item.UserCount);
        }

        console.log(this.lineChartData[0].data);
    });
  }

  ngOnInit(): void {
    this.getUsers();
  }

}
