<div class="mainContent">
  <h2>Key Word Search</h2>

  <div class="jumbotron">
    <p class="lead">
      You can search for substances, therapeutic classes, event terms or drug product names. Currently, it is not
      possible to search for marketing authorisation holders which are entered into the database for centrally
      authorised products. This might be implemented in future database updates.
    </p>
  </div>

  <div style="width: 50%">
    <div class="input-group mb-3">
      <input type="search" class="form-control" placeholder="Search database" aria-label="Search database"
             aria-describedby="search-button" [(ngModel)]="searchTerm" (input)="SubmitSearch()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" id="search-button" (click)="SubmitSearch()">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
            <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div id="results">
    <div class="card search-result-card" *ngFor="let signal of results['Signals']">
      <a [routerLink]="['../wiki/signal/', signal['epitt']]">
        <div class="card-body">
          <h5 class="card-title">
            Signal:
            <span [innerHTML]="findTermInString(searchTerm, signal['epitt'])"></span>
          </h5>
          <p class="card-text" [innerHTML]="findTermInString(searchTerm, signal['term'])"></p>
        </div>
      </a>
    </div>
    <div class="card search-result-card" *ngFor="let substance of results['Substances']">
      <a [routerLink]="['../wiki/substance/', substance['AI_ID']]">
        <div class="card-body">
          <h5 class="card-title" [innerHTML]="findTermInString(searchTerm, substance['INN_EN'])"></h5>
        </div>
      </a>
    </div>
    <div class="card search-result-card" *ngFor="let tclass of results['TherapeuticClasses']">
      <a [routerLink]="['../wiki/therapeutic-class/', tclass['TC_ID']]">
        <div class="card-body">
          <h5 class="card-title" [innerHTML]="findTermInString(searchTerm, tclass['Description_EN'])"></h5>
        </div>
      </a>
    </div>
    <div class="card search-result-card" *ngFor="let product of results['DrugProducts']">
      <a [routerLink]="['../wiki/substance/', product['AI_ID']]">
        <div class="card-body">
          <h5 class="card-title" [innerHTML]="findTermInString(searchTerm, product['EU_Name'])"></h5>
          <p class="card-text">{{product['INN_EN']}}</p>
        </div>
      </a>
    </div>
  </div>
</div>
